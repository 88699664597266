import { EDIT_LISTING_GUEST_INTERACTION,
         CLOSE_LISTING_GUEST_INTERACTION,
         PENDING_SUBMIT_LISTING_GUEST_INTERACTION,
         COMPLETE_SUBMIT_LISTING_GUEST_INTERACTION,
         FAILED_SUBMIT_LISTING_GUEST_INTERACTION
    } from "./mlTypes"


const initialState = {
    pendingsubmit: false,
    failedsubmit: false,
    completesubmit: false,
    propertyguestinteractionediting: false
}


const mlListingGuestAccessReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_LISTING_GUEST_INTERACTION: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertyguestinteractionediting: true
        }

        case CLOSE_LISTING_GUEST_INTERACTION: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertyguestinteractionediting: false
        }

        case PENDING_SUBMIT_LISTING_GUEST_INTERACTION: return {
            ...state,
            pendingsubmit: true,
            failedsubmit: false,
            completesubmit: false
        }

        case COMPLETE_SUBMIT_LISTING_GUEST_INTERACTION: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: true
        }
        
        case FAILED_SUBMIT_LISTING_GUEST_INTERACTION: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: true,
            completesubmit: true
        }
    
        default: return state
    }
}

export default mlListingGuestAccessReducer