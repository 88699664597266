import React from 'react'
import HomeIcon from '@material-ui/icons/Home';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom"
const useStyles = makeStyles((theme) => ({
    contact: {
        color: "#00bc20"
    },
    cancel: {
        color: "#ff3434"
    }
}));

export const ContextSwitcher = (props) => {
    switch (props.notification_type) {
        case "host_request": return (
            <p><span className="user">{props.item.host_request.host_request_from}</span> has sent a host request.</p>
        )

        case "booking_request": return (
            <Link style={{color: "inherit",textDecoration: "inherit"}} to={`/reservations/${props.item.booking_request.booking_id}`}>
            <p>Booking request for <span className="property-item-elem">{props.item.booking_request.booking_for_listing_name}</span> from <span className="user">{props.item.booking_request.booking_from}</span>.</p>
            </Link>
        )

        case "contact_request": return (
            <p>New Query from <span className="user">{props.item.query.query_from}</span>.</p>
        )

        case "booking": return (
            <Link style={{color: "inherit",textDecoration: "inherit"}} to={`/reservations/${props.item.booking_request.booking_id}`}>
            <p><span className="property-item-elem">{props.item.booking_request.booking_for_listing_name}</span> has been booked by <span className="user">{props.item.booking_request.booking_from}</span>.</p>
            </Link>
        )

        case "external_booking": return (
            <Link style={{color: "inherit",textDecoration: "inherit"}} to={`/external_reservations/${props.item.booking_request.booking_id}`}>
            <p><span className="property-item-elem">{props.item.booking_request.booking_for_listing_name}</span> has been booked by <span className="user">{props.item.booking_request.booking_from}</span> from {props.item.booking_request.booking_source}.</p>
            </Link>
        )

        case "cancellation": return (
            <Link style={{color: "inherit",textDecoration: "inherit"}} to={`/reservations/${props.item.cancellation.booking_id}`}>
                <p>Booking of <span className="property-item-elem">{props.item.cancellation.cancellation_for_listing_name}</span> has been cancelled by <span className="user">{props.item.cancellation.booking_cancelled_by}</span>.</p>
            </Link>
        )

        case "external_booking_cancellation": return (
            <Link style={{color: "inherit",textDecoration: "inherit"}} to={`/external_reservations/${props.item.cancellation.booking_id}`}>
                <p>Booking of <span className="property-item-elem">{props.item.cancellation.cancellation_for_listing_name}</span> has been cancelled by <span className="user">{props.item.cancellation.booking_cancelled_by}</span>.</p>
            </Link>
        )
        

        default: return ("")
    }
}


export const IconNotification = (props) => {
    const classes = useStyles();

    switch (props.notificationtype) {
        case "host_request": return (
            <HomeIcon style={{ fontSize: 30 }} color="primary" />
        )

        case "booking_request": return (
            <img alt="Home" className="img-notification-item" src={props.img} />
        )

        case "contact_request": return (
            <ContactSupportIcon style={{ fontSize: 30 }} className={classes.contact} />
        )

        case "booking": return (
            <img alt="Home" className="img-notification-item" src={props.img} />
        )

        case "external_booking": return (
            <img alt="Home" className="img-notification-item" src={props.img} />
        )

        case "cancellation": return (
            <CancelIcon style={{ fontSize: 30 }} className={classes.cancel} />
        )


        case "external_booking_cancellation": return (
            <CancelIcon style={{ fontSize: 30 }} className={classes.cancel} />
        )

        default: return ("")
    }
}