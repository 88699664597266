import React, { useEffect } from 'react'
import { IconButton, Badge, Menu } from '@material-ui/core';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import Notificationbox from "./notificationbox";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { openNotificationMenu, closeNotificationMenu, getNotificationsServer } from "../redux/notification/notificationActions"
import axios from "axios"

const useStyles = makeStyles((theme) => ({
    main: {
        color: "rgba(0, 0, 0, 0.7)",
    },
    second: {
        color: "#0072ff"
    }
}));

function Notification() {
    const { notification_menu_open, anchor, new_notification_indicate, notification } = useSelector(state => state.notification);
    const { token } = useSelector(state => state.auth);
    const { last_updated, new_notification } = useSelector(state => state.sock.notification)
    const signal = axios.CancelToken.source();

    const dispatch = useDispatch()
    const classes = useStyles();

    const handleNotificationOpen = (e) => {
        dispatch(openNotificationMenu(e.currentTarget))
    }

    const handleNotificationClose = () => {
        dispatch(closeNotificationMenu())

        if (notification.notification_data.length !== 0) {
            localStorage.setItem('notificationdtx', notification.notification_data[0].timestamp)
        }

    }

    useEffect(() => {
        dispatch(getNotificationsServer({ "token": token, "signal_token": signal.token }))

        return () => {
            signal.cancel()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (new_notification) {
            dispatch(getNotificationsServer({ "token": token, "signal_token": signal.token }))
        }
        // eslint-disable-next-line
    }, [last_updated])



    return (
        <>
            <div className="notification-bell">
                <IconButton
                    aria-label="notification"
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    className={notification_menu_open ? classes.second : classes.main}
                    onClick={handleNotificationOpen}
                    size="medium"
                >
                    <Badge color="primary" variant="dot" invisible={!new_notification_indicate}>
                        <NotificationsRoundedIcon />
                    </Badge>
                </IconButton>

            </div>
            <Menu
                elevation={2}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: '60ch',
                    },
                }}
                anchorEl={anchor}
                open={notification_menu_open}
                onClose={handleNotificationClose}>
                <Notificationbox />
            </Menu>
        </>

    )
}

export default Notification
