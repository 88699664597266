import React, {useState} from 'react'
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { COOKIE_AUTH_NAME } from "../config";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    main: {
        color: "#fff",
        backgroundColor: "#0072ff",
    },
}));

function Profileicon() {
    const { name, img } = useSelector(state => state.auth);
    const classes = useStyles();
    const [anchor, setanchor] = useState(null);
    const open = Boolean(anchor);
    const cookies = new Cookies();
    const history = useHistory();

    const handleProfileClick = (e) => {
        setanchor(e.currentTarget);
    }

    const handleClose = () => {
        setanchor(null)
    }

    const logoutHandle = () => {
        cookies.remove(COOKIE_AUTH_NAME)
        window.location = "/auth";
    }

    return (
        <>
        <div onClick={handleProfileClick} className="profile-ico">
            <Avatar alt={name} src={img} className={classes.main}>
                {name !== "" ? name.charAt(0) : ""}
            </Avatar>
        </div>
        <Menu
            id="logout"
            anchorEl={anchor}
            keepMounted
            style={{"top":"45px"}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={logoutHandle}>Logout</MenuItem>
      </Menu>
      </>
    )
}

export default Profileicon
