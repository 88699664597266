export const Get_Notification = "private/misc/notifications/20/0"
export const Booking_Requests = "private/booking/booking/booking_request/2/0"
export const Get_Confirmed_Bookings = "private/booking/booking/get_bookings/confirmed/3/0"
export const Booking_Requests_Action = "private/booking/booking/awaiting_approvals/action"
export const Get_Listings = "private/listing/listings/list2/"
export const Get_Listing = "private/listing/listings/view_listing2/"
export const Upload_Featured_Image = "private/listing/listings/edit/1/featured_image"
export const Change_Image_Position = "private/listing/listings/change_pos"
export const Delete_Image = "private/listing/listings/edit/1/delete_image"
export const Multi_Image_Upload = "private/listing/listings/edit/1/multi_page"
export const Edit_Listing = "private/listing/listings/edit/counter_edit"
export const Add_Custom_Period = "private/listing/add_custom_period"
export const Remove_Custom_Period = "private/listing/remove_custom_period"
export const Get_Bookings = "private/booking/booking/get_bookings/confirmed/"
export const Get_Past_Bookings = "private/booking/booking/get_bookings/past/"
export const Get_External_Bookings = "private/booking/get_cm_bookings/"
export const Get_Upcoming_External_Bookings = "private/booking/fetch_upcoming_cm_bookings/"
export const Get_Past_External_Bookings = "private/booking/fetch_past_cm_bookings/"
export const Get_Cancelled_External_Bookings = "private/booking/fetch_cancelled_cm_bookings/"
export const Get_Cancelled_Bookings = "private/booking/booking/get_bookings/cancelled/"
export const Get_Booking = "private/booking/booking/get_booking/"
export const Get_External_Booking = "private/booking/fetch_cm_booking/"
export const Get_Order = "private/booking/booking/get_order/"
export const Booking_Requests_All = "private/booking/booking/booking_request/"
export const Booking_Requests_List_All = "private/booking/booking/booking_request/all/"
export const Get_All_Users = "private/auth/user/list2/"
export const Get_All_Special_Users = "private/auth/user/list3/"
export const Get_All_Drafts = "private/draft/list/"
export const Get_Blocked_Dates = "private/misc/homeowner/block_dates/get/"
export const Add_Block_Date = "private/misc/homeowner/block_dates/add"
export const Delete_Block_Date = "private/misc/homeowner/block_dates/delete"
export const Get_Draft = "private/draft/view_draft/"
export const Draft_Editor = "private/draft/editor"
export const Change_Image_Position_Draft = "private/draft/change_pos"
export const Delete_Image_Draft = "private/draft/delete_image"
export const Upload_Featured_Image_Draft = "private/draft/featured_image"
export const Multi_Image_Upload_Draft = "private/draft/multi_upload"
export const Publish_Draft = "private/draft/publish"
export const Modify_Booking = "private/booking/booking/modify"
export const Fetch_Calendar_Listing = "private/calendar/list/"
export const Fetch_Calendar_Listing_Search = "private/calendar/search/"
export const Create_User = "private/auth/user/create"
export const Resend_OTP = "private/auth/user/resend_otp"
export const Ban_User = "private/auth/user/ban"
export const Unban_User = "private/auth/user/unban"
export const OTA_Sync_Listing = "private/calendar/ota_calendar/"
export const Delete_OTA_Sync = "private/misc/sync_master/delete"
export const List_OTA_Channels = "private/misc/ota_master/list"
export const Add_OTA_Channels = "private/misc/sync_master"
export const Get_Dashboard = "private/misc/dashboard_new/all"
export const Contact_Requests = "private/misc/contact_requests/list/"
export const Host_Requests = "private/misc/host_requests/list/"
export const Contact_Request = "private/misc/contact_request/"
export const GST_Admin_List = "private/misc/gst_master/list"
export const GST_Admin_Update = "private/misc/gst_master"
export const Cancellations_List = "private/booking/booking/public_cancellations/"
export const Download_Invoice = "private/misc/invoicing"
export const Process_Refund = "private/booking/booking/process_refund"
export const Send_Feedback_Link = "private/misc/get_customer_feedback"
export const Publish_Feedback = "private/misc/publish_feedback"
export const Get_Feedback = "public/misc/customer_feedback/"
export const Get_Channel_Manager_Connection = "private/channel_manager/get_connected_cm/"
export const Create_Update_Channel_Manager_Connection = "private/channel_manager/create_connection"
export const Create_Update_Channel_Manager_RU_Connection = "private/channel_manager/create_connection_ru"
export const Delete_Channel_Manager_Connection = "private/channel_manager/remove_connection"
export const Delete_Channel_Manager_RU_Connection = "private/channel_manager/remove_connection_ru"
export const Get_Channel_Custom_Pricing = "private/channel_manager/fetch_custom_periods/"
export const Delete_Channel_Custom_Pricing = "private/channel_manager/remove_custom_period"
export const Delete_Channel_RU_Custom_Pricing = "private/channel_manager/remove_custom_price_ru"
export const Fetch_Inflations = "private/channel_manager/price_inflations"
export const Add_Channel_Custom_Price = "private/channel_manager/set_custom_period"
export const Fetch_Booking_Sheet = "private/booking/fetch_booking_sheet"
export const Add_Channel_RU_Custom_Price = "private/channel_manager/set_custom_price_ru"
export const Add_Update_Booking_Sheet = "private/booking/update_booking_sheet"
export const Fetch_Payout_Sheet = "private/booking/fetch_payout_sheet"