export const PATH_LIST = {
    "/": [1],
    "/listings": [1],
    "/listings/manage/:url": [1],
    "/performance": [1],
    "/users": [1],
    "/users/list": [1],
    "/special/list": [1],
    "/users/add": [1],
    "/reservations": [1],
    "/past_reservations": [1],
    "/external_reservations": [1],
    "/past_external_reservations": [1],
    "/cancelled_external_reservations": [1],
    "/reservations/:booking_id": [1],
    "/external_reservations/:booking_id": [1],
    "/reservation/booking_requests": [1],
    "/reservation/all_booking_requests": [1],
    "/reservation/cancelled_bookings": [1],
    "/listings/drafts": [1],
    "/create_draft/:url": [1],
    "/create_draft": [1],
    "/calendar": [1],
    "/calendar2": [1],
    "/calendar/test": [1],
    "/contact_requests": [1],
    "/onboarding_requests": [1],
    "/manage-misc": [1],
    "/manage-misc/gst-administration": [1],
    "/manage-misc/invoice-generation": [1],
    "/manage-misc/stay-guide": [1],
    "/manage-misc/booking-sheet": [1],
    "/manage-misc/payout-sheet": [1],
}