import { OPEN_NOTIFICATION_MENU, CLOSE_NOTIFICATION_MENU, FETCHED_NOTIFICATION, FETCHING_NOTIFICATION, FAILED_FETCH_NOTIFICATION, NOTIFICATION_NEW_INDICATOR } from "./notificationTypes"


const initialState = {
    "anchor": null,
    "notification_menu_open": false,
    "new_notification_indicate": false,
    "notification": {
        "fetching_new_notification": false,
        "fetched_new_notification": false,
        "fetched_failed": false,
        "notification_data": []
    } 
}


const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_NOTIFICATION_MENU: return {
            ...state,
            "anchor": action.payload,
            "notification_menu_open": true,
            "new_notification_indicate": false 
        }

        case CLOSE_NOTIFICATION_MENU: return {
            ...state,
            "notification_menu_open": false

        }

        case FETCHED_NOTIFICATION: return {
            ...state,
            "notification": {
                "fetching_new_notification": false,
                "fetched_new_notification": true,
                "fetched_failed": false,
                "notification_data": action.payload.notification_data
            }

        }
        
        case FETCHING_NOTIFICATION: return {
            ...state,
            "notification": {
            "fetching_new_notification": true,
            "fetched_failed": false,
            "fetched_new_notification": false
            }
        }

        case FAILED_FETCH_NOTIFICATION: return {
            ...state,
            "notification": {
            "fetched_failed": true,
            "fetching_new_notification": false,
            "fetched_new_notification": false
            }
        }

        case NOTIFICATION_NEW_INDICATOR: return {
            ...state,
            "new_notification_indicate": true
        }
    
        default: return state;
    }
}


export default NotificationReducer