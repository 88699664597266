import { createMuiTheme } from '@material-ui/core/styles';

export const lightTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#0072ff",
      },
      secondary: {
        main: "#cccccc",
      },
    },
    typography: {
      fontFamily: [
        "Commissioner",
        "sans-serif",
      ].join(','),
    },
  });