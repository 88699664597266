import React, {useState, useEffect} from 'react'
import {Grid} from '@material-ui/core'
import {useLocation, useHistory} from "react-router-dom"
function Navigators() {
    const location = useLocation()
    const history = useHistory()
    const [page, setPage] = useState(null)
    useEffect(() => {
       if(location.pathname === "/external_reservations") {
           setPage({"title":"Upcoming External Bookings", "path": "/past_external_reservations", "pathname": "Past External Reservations"})
       }

       if(location.pathname === "/past_external_reservations") {
           setPage({"title": "Past External Bookings", "path": "/external_reservations", "pathname": "Upcoming External Reservations"})
       }

       if(location.pathname === "/cancelled_external_reservations") {
        setPage({"title": "Cancelled External Reservations", "path": "/external_reservations", "pathname": "Upcoming External Reservations"})
       }
       
    }, [location])


    return (
        <div className="header" style={{marginBottom: "20px"}}>
            <Grid container justify="space-around" spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                <h2>{page !== null && page.title}</h2>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container justify="flex-end" alignItems="center" spacing={2}>
                        <Grid item>
                            <button onClick={() => {
                                page !== null && history.push(page.path)
                            }}>{page !== null && page.pathname}</button>
                        </Grid>
                        <Grid item>
                            <button onClick={() => {
                                history.push("/cancelled_external_reservations")
                            }}>Cancelled Bookings</button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Navigators
