import React, { Suspense, lazy } from 'react'
import {  Route, Switch } from 'react-router-dom'
import ExternalReservationDetail from '../pages/external-reservation-detail';
import ExternalCancelledReservations from '../pages/external-reservations/cancelled-reservations';
import ExternalPastReservations from '../pages/external-reservations/past-reservations';
import PastReservations from '../pages/reservations/past-reservations';
import { Fallback, NotFound } from "../pages/static/index"
import Protected from "./protected";
const Auth = lazy(() => import("../pages/auth/index"));
const DashboardLanding = lazy(() => import("../pages/dashboard-landing/index"));
const ListingsLanding = lazy(() => import("../pages/listings"));
const ManageListings = lazy(() => import("../pages/manage-listings"));
const PerformanceLanding = lazy(() => import("../pages/performance"));
const UsersList = lazy(() => import("../pages/users/user_list"));
const SpecialUsersList = lazy(() => import("../pages/users/special_user_list"));
const UsersAdd = lazy(() => import("../pages/users/user_add"));
const Reservations = lazy(() => import("../pages/reservations"));
const ReservationDetails = lazy(() => import("../pages/reservation-detail"));
const BookingRequests = lazy(() => import("../pages/booking-request"));
const AllBookingRequests = lazy(() => import("../pages/all-booking-request"));
const Drafts = lazy(() => import("../pages/drafts"));
const CancelledReservations = lazy(() => import("../pages/cancelled_reservations"));
const CreateDraft = lazy(() => import("../pages/create-draft"));
const Calendar2 = lazy(() => import("../pages/calendar/index2"));
const TestCalendar = lazy(() => import("../pages/calendar/test/index3"));
const ContactRequests = lazy(() => import('../pages/contact-requests'));
const HostRequests = lazy(() => import("../pages/hosting-request"));
const ManageMisc = lazy(() => import("../pages/manage-misc"));
const GSTAdmin = lazy(() => import("../components/manage-misc/gst-admin"));
const InvoiceGeneration = lazy(() => import("../components/manage-misc/invoice-generation"));
const StayGuide = lazy(() => import("../components/manage-misc/stay-guide"));
const BookingSheet = lazy(() => import("../components/manage-misc/booking-sheet"));
const ExternalReservations = lazy(() => import("../pages/external-reservations"));
const PayoutSheet = lazy(() => import("../components/manage-misc/payout-sheet"));


/**
 * All routes are mentioned here for all individual pages.
 *
 * 
 * Individual page components associated with routes are loaded using the new
 * Suspense and Lazy methods, to reduce the javascript bundle size. 
 * Easy individual component is loaded only when the routes are fetched. 
 * Suspense takes a fallback method which is displayed when the route is
 * loading. 
 * 
 * 
 */

function routes() {

    return (
            <Suspense fallback={<Fallback />}>
                <Switch>
                    <Route exact path="/auth" component={Auth} />
                    <Protected exact path="/" component={DashboardLanding} />
                    <Protected exact path="/listings" component={ListingsLanding} />
                    <Protected path="/listings/manage/:url" component={ManageListings} />
                    <Protected path="/create_draft/:url" component={CreateDraft} />
                    <Protected path="/create_draft" component={CreateDraft} />
                    <Protected exact path="/performance" component={PerformanceLanding} />
                    <Protected exact path="/users/add" component={UsersAdd} />
                    <Protected exact path="/users/list" component={UsersList} />
                    <Protected exact path="/special/list" component={SpecialUsersList}/>
                    <Protected exact path="/reservations" component={Reservations} />
                    <Protected exact path="/past_reservations" component={PastReservations} />
                    <Protected exact path="/external_reservations" component={ExternalReservations} />
                    <Protected exact path="/past_external_reservations" component={ExternalPastReservations} />
                    <Protected exact path="/cancelled_external_reservations" component={ExternalCancelledReservations} />
                    <Protected exact path="/contact_requests" component={ContactRequests} />
                    <Protected exact path="/onboarding_requests" component={HostRequests} />
                    <Protected exact path="/reservations/:booking_id" component={ReservationDetails} />
                    <Protected exact path="/external_reservations/:booking_id" component={ExternalReservationDetail} />
                    <Protected exact path="/reservation/booking_requests" component={BookingRequests} />
                    <Protected exact path="/reservation/all_booking_requests" component={AllBookingRequests} />
                    <Protected exact path="/reservation/cancelled_bookings" component={CancelledReservations} />
                    <Protected exact path="/listings/drafts" component={Drafts} />
                    {/* <Protected exact path="/calendar" component={Calendar2} /> */}
                    <Protected exact path="/calendar" component={TestCalendar} />
                    <Protected exact path="/manage-misc" component={ManageMisc} />
                    <Protected exact path="/manage-misc/gst-administration" component={GSTAdmin} />
                    <Protected exact path="/manage-misc/invoice-generation" component={InvoiceGeneration} />
                    <Protected exact path="/manage-misc/stay-guide" component={StayGuide} />
                    <Protected exact path="/manage-misc/booking-sheet" component={BookingSheet} />
                    <Protected exact path="/manage-misc/payout-sheet" component={PayoutSheet} />
                    <Route path='*' exact component={NotFound} />
                </Switch>
            </Suspense>
    )
}

export default routes
