import { EDIT_LISTING_GETTING_AROUND,
         CLOSE_LISTING_GETTING_AROUND,
         PENDING_SUBMIT_LISTING_GETTING_AROUND,
         COMPLETE_SUBMIT_LISTING_GETTING_AROUND,
         FAILED_SUBMIT_LISTING_GETTING_AROUND
    } from "./mlTypes"


const initialState = {
    pendingsubmit: false,
    failedsubmit: false,
    completesubmit: false,
    propertygettingaroundediting: false
}


const mlListingGettingAround = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_LISTING_GETTING_AROUND: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertygettingaroundediting: true
        }

        case CLOSE_LISTING_GETTING_AROUND: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertygettingaroundediting: false
        }

        case PENDING_SUBMIT_LISTING_GETTING_AROUND: return {
            ...state,
            pendingsubmit: true,
            failedsubmit: false,
            completesubmit: false
        }

        case COMPLETE_SUBMIT_LISTING_GETTING_AROUND: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: true
        }
        
        case FAILED_SUBMIT_LISTING_GETTING_AROUND: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: true,
            completesubmit: true
        }
    
        default: return state
    }
}

export default mlListingGettingAround