import {EDIT_LISTING_DESC, CLOSE_LISTING_DESC,
         PENDING_SUBMIT_LISTING_DESC,
         COMPLETE_SUBMIT_LISTING_DESC,
         FAILED_SUBMIT_LISTING_DESC
    } from "./mlTypes"


const initialState = {
    pendingsubmit: false,
    failedsubmit: false,
    completesubmit: false,
    propertydescediting: false
}


const mlListingDescReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_LISTING_DESC: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertydescediting: true
        }

        case CLOSE_LISTING_DESC: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertydescediting: false
        }

        case PENDING_SUBMIT_LISTING_DESC: return {
            ...state,
            pendingsubmit: true,
            failedsubmit: false,
            completesubmit: false
        }

        case COMPLETE_SUBMIT_LISTING_DESC: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: true
        }
        
        case FAILED_SUBMIT_LISTING_DESC: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: true,
            completesubmit: true
        }
    
        default: return state
    }
}

export default mlListingDescReducer