import {CLEAR_ON_UNMOUNT,LISTING_SKIP,HAS_NO_MORE,LISTINGS_LOADING,ADD_LISTING} from "./listingsTypes"

const initialState = {
    "listing": [],
    "listings_loading": false,
    "listing_total": 0,
    "hasmore": true,
    "currentindex_listing": 0
}

const listingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LISTING: return {
            ...state,
            "listings_loading": false,
            "listing": [...state.listing, ...action.payload.results],
            "listing_total": action.payload.total
        }

        case CLEAR_ON_UNMOUNT: return {
            ...state,
            "listings_loading": false,
            "listing": [],
            "listing_total": 0,
            "currentindex_listing": 0
        }

        case LISTINGS_LOADING: return {
            ...state,
            "listings_loading": true
        }

        case HAS_NO_MORE: return {
            ...state,
            "hasmore": false
        }

        case LISTING_SKIP: return {
            ...state,
            "currentindex_listing": state.currentindex_listing + 10
        }
        
        default: return state
    }
}

export default listingsReducer