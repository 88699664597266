import {CLEAR_ON_UNMOUNT,LISTING_SKIP, HAS_NO_MORE,ADD_LISTING, ADD_SEARCH_PROPERTIES, START_SEARCH_TYPING, FOCUS, DEFOCUS, LISTINGS_LOADING} from "./listingsTypes"
import { Search_Properties } from "../../api/public/index"
import { Get_Listings } from "../../api/private/index"
import httpClient from "../../api/httpClient";
import httpClientPrivate from "../../api/httpClientPrivate"

export const startTyping = (payload) => {
    return {
        type: START_SEARCH_TYPING,
        payload: payload
    }
}

export const focusSearchbox = () => {
    return {
        type: FOCUS
    }
}

export const defocusSearchbox = () => {
    return {
        type: DEFOCUS
    }
}

export const addSearchProperties = (payload) => {
    return {
        type: ADD_SEARCH_PROPERTIES,
        payload: payload
    }
}


export const startedTypingFetchData = (payload) => dispatch => {
    //dispatch(startTyping(payload))
    httpClient(Search_Properties + payload,{},"GET")
    .then(res => {
        
        if(res.status === 200) {
            dispatch(addSearchProperties(res.data))
        }
        
    })

}

export const addListings = (payload) => {
    return {
        type: ADD_LISTING,
        payload: payload
    }
}

export const addListingsLoading = () => {
    return {
        type: LISTINGS_LOADING,
    }
}

export const setEndToFetchListings = () => {
    return {
        type: HAS_NO_MORE
    }
}

export const paginateSkip = () => {
    return {
        type: LISTING_SKIP
    }
}

export const clearOnUnmount = () => {
    return {
        type: CLEAR_ON_UNMOUNT
    }
}

export const updateMoreListings = (payload) => dispatch => {
    dispatch(addListingsLoading())
    httpClientPrivate(Get_Listings + 10 + "/" + payload.skip, {},"GET",payload.token)
    .then(res => {
        if(res.data.result.length === 0) {
            dispatch(setEndToFetchListings())
        } 

        const obj = {"results": res.data.result,"total": res.data.count}
        console.log(obj)
        dispatch(addListings(obj))
    })
}

export const getListings = (payload) => dispatch => {
    dispatch(addListingsLoading())
    httpClientPrivate(Get_Listings + 10 + "/" + 0,{},"GET",payload.token)
    .then(res => {
        const obj = {"results": res.data.result,"total": res.data.count}
        console.log(obj)
        dispatch(addListings(obj))
    })
}