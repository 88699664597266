import {CONNECT, CONNECTED, DISCONNECT, NEW_NOTIFICATION} from "./socketTypes"


const initialState = {
    "socket_connected": false,
    "socket":null,
    "notification": {
        "notification_msg":"",
        "notification_type":"",
        "new_notification": false,
        "last_updated": "",
    },
    "new_booking_request": false,
    "new_booking": false
}

const socketReducer = (state = initialState, action) => {
    switch(action.type) {
        case CONNECT: return {
            ...state,
            socket: action.payload
        }

        case CONNECTED: return {
            ...state,
            socket_connected: true
        }

        case DISCONNECT: return {
            ...state,
            socket: null,
            socket_connected: false
        }

        case NEW_NOTIFICATION: return {
            ...state,
            notification: {
                notification_msg: action.payload.notification_msg,
                notification_type: action.payload.notification_type,
                last_updated: Date.now(),
                new_notification: true
            }
        }

        default: return state
    }
}

export default socketReducer