import {CONNECT, CONNECTED, DISCONNECT, NEW_NOTIFICATION} from "./socketTypes"
import io from "socket.io-client"

export const sockConnect = (payload) => {
    return {
        type: CONNECT,
        payload: payload
    }
}

export const connected = () => {
    return {
        type: CONNECTED
    }
}

export const disconnect = () => {
    return {
        type: DISCONNECT
    }
}

export const newNotification = (payload) => {
    return {
        type: NEW_NOTIFICATION,
        payload: payload
    }
}

export const establishConnection = (payload) => dispatch => {
    const sock = io(payload.url, { transports: ['websocket', 'polling', 'flashsocket'] })
    sock.once('connect', () => {
        dispatch(sockConnect(sock))
        dispatch(connected())
    })
    
}

export const disconnectConnection = (payload) => dispatch => {
    const sock = payload
    sock.close()
    dispatch(disconnect())
}
