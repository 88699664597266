import {BOOKING_REQUEST_ACTION_PENDING, BOOKING_REQUEST_ACTION_FAILED, BOOKING_REQUEST_ACTION_COMPLETE,NEW_BOOKING_REQUEST, NEW_CONFIRMED_REQUEST, FETCHING_BOOKING_REQUEST, FETCHING_CONFIRMED_BOOKINGS, ADD_BOOKING_REQUESTS, ADD_CONFIRMED_BOOKINGS, ACTIVE_BOOKING_REQUEST, ACTIVE_CONFIRMED_BOOKINGS, BOOKING_REQUEST_ACCEPT_ACTION_1, BOOKING_REQUEST_REJECT_ACTION_1, BOOKING_REQUEST_CANCEL_ACTION} from "./dlTypes"

const initialState = {
    "new_booking_request": false,
    "new_confirmed_request": false,
    "fetching_new_booking_data": false,
    "fetching_new_confirmed_bookings": false,
    "booking_request_data": [],
    "confirmed_bookings_data": [],
    "booking_requests_tab_active": true,  
    "confirmed_bookings_tab_active": false,
    "see_more_booking_request": 0,
    "booking_request_accepted_dialog": false,
    "booking_request_rejected_dialog": false,
    "booking_dialog_open": false,
    "booking_request_action_item": null,
    "booking_request_action": {
        "pending": false,
        "complete": false,
        "failed": false
    }
}



const dlReducer = (state = initialState, action) => {
    switch (action.type) {
        case NEW_BOOKING_REQUEST: return {
            ...state,
            new_booking_request: true
        }
        case NEW_CONFIRMED_REQUEST: return {
            ...state,
            new_confirmed_request: true
        }

        case FETCHING_BOOKING_REQUEST: return {
            ...state,
            fetching_new_booking_data: true,

        }

        case FETCHING_CONFIRMED_BOOKINGS: return {
            ...state,
            fetching_new_confirmed_bookings: true
        }

        case ADD_BOOKING_REQUESTS: return {
            ...state,
            fetching_new_booking_data: false,
            booking_request_data: action.payload.data,
            see_more_booking_request: action.payload.see_more
        }

        case ADD_CONFIRMED_BOOKINGS: return {
            ...state,
            fetching_new_confirmed_bookings: false,
            confirmed_bookings_data: action.payload
        }

        case ACTIVE_BOOKING_REQUEST: return {
            ...state,
            new_booking_request: false,
            booking_requests_tab_active: true,
            confirmed_bookings_tab_active: false
        }

        case ACTIVE_CONFIRMED_BOOKINGS: return {
            ...state,
            new_confirmed_request: false,
            booking_requests_tab_active: false,
            confirmed_bookings_tab_active: true
        }

        case BOOKING_REQUEST_ACCEPT_ACTION_1: return {
            ...state,
            booking_dialog_open: true,
            booking_request_accepted_dialog: true,
            booking_request_rejected_dialog: false,
            booking_request_action_item: action.payload
        }

        case BOOKING_REQUEST_REJECT_ACTION_1: return {
            ...state,
            booking_dialog_open: true,
            booking_request_accepted_dialog: false,
            booking_request_rejected_dialog: true,
            booking_request_action_item: action.payload
        }

        case BOOKING_REQUEST_CANCEL_ACTION: return {
            ...state,
            booking_dialog_open: false,
            booking_request_accepted_dialog: false,
            booking_request_rejected_dialog: false,
            booking_request_action_item: null
        }

        case BOOKING_REQUEST_ACTION_PENDING: return {
            ...state,
            booking_request_action: {
                "pending": true,
                "complete": false,
                "failed": false
            }
        }

        case BOOKING_REQUEST_ACTION_COMPLETE: return {
            ...state,
            booking_request_action: {
                "pending": false,
                "complete": true,
                "failed": false
            },
            booking_request_action_item: null,
            booking_dialog_open: false,
            booking_request_accepted_dialog: false,
            booking_request_rejected_dialog: false,
        }

        case BOOKING_REQUEST_ACTION_FAILED: return {
            ...state,
            booking_request_action: {
                "pending": false,
                "complete": false,
                "failed": true
            },
            booking_request_action_item: null,
            booking_dialog_open: false,
            booking_request_accepted_dialog: false,
            booking_request_rejected_dialog: false,

        }
    
        default: return state
    }
}

export default dlReducer