import {ADD_SEARCH_PROPERTIES,START_SEARCH_TYPING, FOCUS, DEFOCUS} from "./listingsTypes"

const initialState = {
    "searchbox": {
        "focused": false,
        "started_typing": false,
        "typing_text": "",
        "properties": []

    }
}


const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_SEARCH_TYPING: return {
            ...state,
            searchbox: {
                ...state.searchbox,
                "started_typing": true,
                "typing_text": action.payload
            }
        }

        case FOCUS: return {
            ...state,
            searchbox: {
                ...state.searchbox,
                "focused": true
            }
        }

        case DEFOCUS: return {
            ...state,
            searchbox: {
                ...state.searchbox,
                "focused": false
            }
        }
        case ADD_SEARCH_PROPERTIES: return {
            ...state,
            searchbox: {
                ...state.searchbox,
                "properties": action.payload
            }
        }

        default: return state

    }
}

export default searchReducer