import axios from 'axios';
import { API_URI } from ".././config"

/**
 * httpClient takes an endpoint, payload and a method and
 * returns a promise.
 * 
 * Uses Axios.
 * 
 */

async function httpClient(endpoint, payload, method) {
    var data = JSON.stringify(payload);
    var config = {
        method: method,
        url: API_URI + endpoint,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = await axios(config)

    return res;
}

export default httpClient
