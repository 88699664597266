export const PENDING_FETCH_DRAFT = "PENDING_FETCH_DRAFT"
export const SUCCESS_FETCH_DRAFT = "SUCCESS_FETCH_DRAFT"
export const FAILED_FETCH_DRAFT = "FAILED_FETCH_DRAFT"
export const MD_OPEN_COVER_PHOTO_CHANGE = "MD_OPEN_COVER_PHOTO_CHANGE"
export const MD_CLOSE_COVER_PHOTO_CHANGE = "MD_CLOSE_COVER_PHOTO_CHANGE"
export const MD_OPEN_MANY_PHOTO_CHANGE = "MD_OPEN_MANY_PHOTO_CHANGE"
export const MD_CLOSE_MANY_PHOTO_CHANGE = "MD_CLOSE_MANY_PHOTO_CHANGE"
export const MD_PENDING_PHOTO_SUBMIT = "MD_PENDING_PHOTO_SUBMIT"
export const MD_COMPLETE_PHOTO_SUBMIT = "MD_COMPLETE_PHOTO_SUBMIT"
export const MD_FAILED_PHOTO_SUBMIT = "MD_FAILED_PHOTO_SUBMIT"
export const MD_PENDING_PHOTO_POSITION_CHANGE = "MD_PENDING_PHOTO_POSITION_CHANGE"
export const MD_COMPLETE_PHOTO_POSITION_CHANGE = "MD_COMPLETE_PHOTO_POSITION_CHANGE"
export const MD_FAILED_PHOTO_POSITION_CHANGE = "MD_FAILED_PHOTO_POSITION_CHANGE"
export const MD_PENDING_PHOTO_DELETE = "MD_PENDING_PHOTO_DELETE"
export const MD_COMPLETE_PHOTO_DELETE = "MD_COMPLETE_PHOTO_DELETE"
export const MD_FAILED_PHOTO_DELETE = "MD_FAILED_PHOTO_DELETE"
export const MD_PENDING_MULTIPLE_IMAGE_UPLOAD = "MD_PENDING_MULTIPLE_IMAGE_UPLOAD"
export const MD_COMPLETE_MULTIPLE_IMAGE_UPLOAD = "MD_COMPLETE_MULTIPLE_IMAGE_UPLOAD"
export const MD_FAILED_MULTIPLE_IMAGE_UPLOAD = "MD_FAILED_MULTIPLE_IMAGE_UPLOAD"