import React from 'react'
import Header from './components/header'
import Footer from './components/footer'

/**
 * Layout container for all children pages. 
 * Here we mention the Header and Footer as constant. 
 * It wraps all chidren components with a `root-container` class
 * it provides a way of implementing a project-wise theme management.
 * 
 */

function layout(props) {
    return (
        <div className="root-container">
            <Header />
            {props.children}
            <Footer />
        </div>
    )
}

export default layout
