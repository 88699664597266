import React from 'react'
import NotFoundPoster from "../../assets/media/vectors/undraw_page_not_found.svg"
import EmptyImg from "../../assets/media/vectors/undraw_empty.svg"
import { CircularProgress, Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * All static components are mentioned here.
 */


export const ImageF = props => {
    const [imgElem, setImgElem] = React.useState(null);

    React.useEffect(() => {
            const i = new Image()
            i.src = props.src
            i.alt = props.alt
            i.decode()
            .then(() => {
                setImgElem(i)
            })
    
        return () => {
            setImgElem(null)
        }

    },[props.src, props.alt])

    return (
        <>
            {
                 imgElem !== null && <img key={imgElem.src} src={imgElem.src} alt={imgElem.alt} />
            }
            
            {
                 imgElem === null && <div className="loading"><CircularProgress size={20}/></div> 
            }
        </>
    )
}


export function Fallback() {
    return (
        <div className="loading">
            <CircularProgress />
        </div>
    )
}

export function Empty() {
    return (
        <div className="empty">
            <img src={EmptyImg} alt="nothing here"/>
            <p>Umm, no booking requests.</p>
        </div>
    )
}

export function FallbackConfirmedBookings() {
    return (
        <Grid container spacing={3}>
           <Grid item xs={3}><Skeleton animation="wave" height={94}/></Grid>
           <Grid item xs={9}><Skeleton animation="wave" height={94}/></Grid>
        </Grid>
    )
}


export function FallbackBookingRequests() {
    return (
        <>
        <Grid container spacing={3}>
           <Grid item xs={3}><Skeleton animation="wave" height={94}/></Grid>
           <Grid item xs={9}><Skeleton animation="wave" height={94}/></Grid>
        </Grid>
        <Grid container justify="space-between" spacing={3}>
            <Grid item xs={4}><Skeleton animation="wave" height={24}/></Grid>
            <Grid item xs={4}><Skeleton animation="wave" height={24}/></Grid>
            <Grid item xs={4}><Skeleton animation="wave" height={24}/></Grid>
        </Grid>
        <Grid container justify="space-between" spacing={4}>
            <Grid item xs={6} sm={6} md={4}><Skeleton animation="wave" height={34}/></Grid>
            <Grid item xs={6} sm={6} md={4}><Skeleton animation="wave" height={34}/></Grid>
        </Grid>
        </>
    )
}



export function FallbackNotification() {
    return (
        <Grid container>
                    <Grid item xs={2}>
                    <Skeleton height={45} width={45} variant="circle"/>
                    </Grid>
                    <Grid item xs={10}><Skeleton animation="wave" height={45} variant="rect"/></Grid>
        </Grid>
    )
}

export function NotFound() {
    return (
        <div className="not-found">
            <div className="not-found-content">
                <img src={NotFoundPoster} alt="Page not Found" />
                <p>Looks like we don't have that page.</p>
            </div>
        </div>
    )
}

