import React, {useEffect, useRef, useCallback, useState} from 'react'
import TableBody from '@material-ui/core/TableBody';
import {Checkbox, Grid} from "@material-ui/core"
import {useDispatch, useSelector} from "react-redux"
import {getListings,paginateSkip,updateMoreListings,clearOnUnmount} from "../../redux/listings/listingsActions"
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import moment from "moment"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {  Link } from 'react-router-dom'
import { Get_Bookings, Get_External_Bookings, Get_Past_External_Bookings } from "../../api/private/index"
import httpClientPrivate, {httpListingClient} from "../../api/httpClientPrivate"
import Loader from "react-loader-spinner";
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Navigators from '../../components/external-reservations/navigators';

function ExternalPastReservations() {
    
    const [hasmore, sethasmore] = useState(true)
    const [bookings, setbookings] = useState([])
    const [bookings_loading, setBookings_loading] = useState(false)
    const [bookings_total, setBookings_total] = useState(0)
    const [currentindex_booking, setcurrentindex_booking] = useState(0)

    const {token} = useSelector(state => state.auth)
    const observer = useRef()

    const lastElmRef = useCallback(node => {
        if (bookings_loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting && hasmore) {
                //dispatch(paginateSkip())
                setcurrentindex_booking(oldidx => oldidx + 10)
            }
        })

        if (node) observer.current.observe(node)
        // eslint-disable-next-line
    },[bookings_loading])


    const updateMoreBookings = (payload) => {
        setBookings_loading(true)

        httpListingClient(Get_Past_External_Bookings + 10 + "/" + payload.skip, {}, "GET", payload.token, "", "", "v2")
        .then(res => {
            if(res.data.length === 0) {
                sethasmore(false)
            } 
    
            setBookings_loading(false)
            setbookings(oldBookings => [...oldBookings, ...res.data])
        })
        .catch(() => {
            setBookings_loading(false)
        })
    }
    

    const getBookings = (payload) => {
        //dispatch(addListingsLoading())
        setBookings_loading(true)

        httpListingClient(Get_Past_External_Bookings + 10 + "/" + 0, {}, "GET", payload.token, "", "", "v2")
        .then(res => {
            setBookings_loading(false)
            setbookings(oldBookings => [...oldBookings, ...res.data])
        })
        .catch(() => {
            setBookings_loading(false)
        })
    }


    useEffect(() => {
        if(currentindex_booking !== 0) {
            const payload = {"token":token,"skip":currentindex_booking}
            updateMoreBookings(payload)
        }
        // eslint-disable-next-line
    },[currentindex_booking])

    useEffect(() => {
        if (bookings.length === 0) {
            const payload = {"token":token}
            getBookings(payload)
        }

        return () => {
            //dispatch(clearOnUnmount())
            setBookings_loading(false)
            setbookings([])
            setcurrentindex_booking(0)
        }
    // eslint-disable-next-line
    }, [])



    return (
        <div className="fixed-padding dashboard-wrapper">
        <Navigators/>
        <div className="table">
            <div className="table-header">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width="2%">
                                        <div className="title-table">
                                            <Grid container>
                                                <Grid item>
                                                    Source
                                                </Grid>
                                                
                                            </Grid>
                                        </div>
                                    </TableCell>
                                    <TableCell width="4%">
                                        <div className="title-table">
                                            <Grid container>
                                                <Grid item>
                                                  Status
                                                </Grid>
                                                
                                            </Grid>
                                        </div>
                                    </TableCell>
                                <TableCell width="24%">
                                    <div className="title-table">
                                        <Grid container>
                                            <Grid item>
                                                Rental
                                            </Grid>
                                           
                                        </Grid>
                                    </div>
                                </TableCell>
                                <TableCell width="5%">
                                    <div className="title-table">
                                        <Grid container spacing={1}>
                                            <Grid item lg={10}>
                                                <div className="title-head-item">
                                                    Booked by
                                                </div>
                                                
                                            </Grid>
                                            
                                        </Grid>
                                    </div>
                                </TableCell>                                
                                <TableCell width="5%">
                                    <div className="title-table">
                                        <Grid container spacing={1}>
                                            <Grid item lg={10}>
                                                
                                                <div className="title-head-item">
                                                Check-in
                                                </div>
                                            </Grid>
                                            
                                        </Grid>
                                    </div>
                                </TableCell>
                                <TableCell width="5%">
                                    <div className="title-table">
                                        <Grid container spacing={1}>
                                            <Grid item lg={10}>
                                                
                                                <div className="title-head-item">
                                                Check-out
                                                </div>
                                            </Grid>
                                           
                                        </Grid>
                                    </div>
                                </TableCell>

                                <TableCell width="10%">
                                    <div className="title-table">
                                        <Grid container spacing={1}>
                                            <Grid item lg={10}>
                                            <div className="title-head-item">
                                                Booked on
                                                </div>
                                            </Grid>
                                          
                                        </Grid>
                                    </div>
                                </TableCell>
                                <TableCell width="5%">
                                    <div className="title-table">
                                        <Grid container spacing={1}>
                                            <Grid item lg={10}>
                                            <div className="title-head-item">
                                                Booking id
                                                </div>
                                            </Grid>
                                          
                                        </Grid>
                                    </div>
                                </TableCell>
                                <TableCell width="5%">
                                    <div className="title-table">
                                        <Grid container spacing={1}>
                                            <Grid item lg={10}>
                                            <div className="title-head-item">
                                                Booking amount
                                                </div>
                                            </Grid>
                                       
                                        </Grid>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bookings.length > 0 && bookings.map((item,idx) => (
                                    <TableRow className="tbl-cell" {...{...(bookings.length === idx + 1 && {ref:lastElmRef})}} key={idx}>
                                        <TableCell component="th" scope="row">
                                            <Grid container alignItems="center">
                                                <Grid item xs={12}>
                                                   <div className="booking-source">
                                                       {item.booking_source.toUpperCase()}
                                                   </div>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Grid container alignItems="center">
                                                <Grid item xs={12}>
                                                   
                                                       {moment().isSameOrAfter(moment(item.date_from)) && moment().isBefore(moment(item.date_to)) && (
                                                          <div className={`booking-status-hosting`}>
                                                            Currently Hosting
                                                            </div>
                                                        )}
                                                  
                                                   
                                                       {moment(moment().format("YYYY-MM-DD")).isSame(moment(item.date_to).format("YYYY-MM-DD")) && (
                                                           <div className="booking-status-checkout">Checking out today</div>
                                                        )}
                                                    
                                                   
                                                       {moment(moment().add(1,"days").format("YYYY-MM-DD")).isSame(moment(item.date_from).format("YYYY-MM-DD")) && (
                                                           <div className="booking-status-checkin">
                                                               Checking in tomorrow
                                                           </div>
                                                        )}
                                                   
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Grid container alignItems="center">
                                                
                                                <Grid item xs={12}>
                                                    
                                                    {item.property_name}
                                                    
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {item.billed_to}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {moment(item.date_from).format("DD/MM/YYYY")}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {moment(item.date_to).format("DD/MM/YYYY")}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {moment(item.time_stamp).format("DD/MM/YYYY hh:mm A")}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div className="booking-id"><Link to={`/external_reservations/${item.booking_id}`}>{item.booking_id}</Link></div>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <strong>INR {item.booking_amount}</strong>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                            ))}

                            </TableBody>
                    </Table>
                </TableContainer>
                {hasmore && bookings_loading && (
                    <div className="loading-listing">
                        <Loader type="ThreeDots" color="#0072ff" height={80} width={80} />
                    </div>    
                )}
            </div>
        </div>
        </div>
            
    )
}

export default ExternalPastReservations