import React, {forwardRef} from 'react'
import { Divider } from '@material-ui/core';
import NotificationItems from "./notificationitems";

const Notificationbox = forwardRef((props,ref) => {
    return (
        <div className="notification-box">
            <div className="title-notification">
                <h2>Notification</h2>
            </div>
            <Divider />
            <div className="notification-item">
                <NotificationItems />
            </div>
        </div>
    )
})

export default Notificationbox
