export const PENDING_FETCH_LISTING = "PENDING_FETCH_LISTING"
export const SUCCESS_FETCH_LISTING = "SUCCESS_FETCH_LISTING"
export const FAILED_FETCH_LISTING = "FAILED_FETCH_LISTING"
export const OPEN_COVER_PHOTO_CHANGE = "OPEN_COVER_PHOTO_CHANGE"
export const CLOSE_COVER_PHOTO_CHANGE = "CLOSE_COVER_PHOTO_CHANGE"
export const OPEN_MANY_PHOTO_CHANGE = "OPEN_MANY_PHOTO_CHANGE"
export const CLOSE_MANY_PHOTO_CHANGE = "CLOSE_MANY_PHOTO_CHANGE"
export const PENDING_PHOTO_SUBMIT = "PENDING_PHOTO_SUBMIT"
export const COMPLETE_PHOTO_SUBMIT = "COMPLETE_PHOTO_SUBMIT"
export const FAILED_PHOTO_SUBMIT = "FAILED_PHOTO_SUBMIT"
export const PENDING_PHOTO_POSITION_CHANGE = "PENDING_PHOTO_POSITION_CHANGE"
export const COMPLETE_PHOTO_POSITION_CHANGE = "COMPLETE_PHOTO_POSITION_CHANGE"
export const FAILED_PHOTO_POSITION_CHANGE = "FAILED_PHOTO_POSITION_CHANGE"
export const PENDING_PHOTO_DELETE = "PENDING_PHOTO_DELETE"
export const COMPLETE_PHOTO_DELETE = "COMPLETE_PHOTO_DELETE"
export const FAILED_PHOTO_DELETE = "FAILED_PHOTO_DELETE"
export const PENDING_MULTIPLE_IMAGE_UPLOAD = "PENDING_MULTIPLE_IMAGE_UPLOAD"
export const COMPLETE_MULTIPLE_IMAGE_UPLOAD = "COMPLETE_MULTIPLE_IMAGE_UPLOAD"
export const FAILED_MULTIPLE_IMAGE_UPLOAD = "FAILED_MULTIPLE_IMAGE_UPLOAD"
export const PENDING_SUBMIT_LISTING_TITLE = "PENDING_SUBMIT_LISTING_TITLE"
export const COMPLETE_SUBMIT_LISTING_TITLE = "COMPLETE_SUBMIT_LISTING_TITLE"
export const FAILED_SUBMIT_LISTING_TITLE = "FAILED_SUBMIT_LISTING_TITLE"
export const CLOSE_EDIT_LISTING_TITLE = "CLOSE_EDIT_LISTING_TITLE"
export const EDIT_LISTING_TITLE = "EDIT_LISTING_TITLE"
export const EDIT_LISTING_DESC = "EDIT_LISTING_DESC"
export const CLOSE_LISTING_DESC = "CLOSE_LISTING_DESC"
export const PENDING_SUBMIT_LISTING_DESC = "PENDING_SUBMIT_LISTING_DESC"
export const COMPLETE_SUBMIT_LISTING_DESC = "COMPLETE_SUBMIT_LISTING_DESC"
export const FAILED_SUBMIT_LISTING_DESC = "FAILED_SUBMIT_LISTING_DESC"
export const EDIT_LISTING_SPACE = "EDIT_LISTING_SPACE"
export const CLOSE_LISTING_SPACE = "CLOSE_LISTING_SPACE"
export const PENDING_SUBMIT_LISTING_SPACE = "PENDING_SUBMIT_LISTING_SPACE"
export const COMPLETE_SUBMIT_LISTING_SPACE = "COMPLETE_SUBMIT_LISTING_SPACE"
export const FAILED_SUBMIT_LISTING_SPACE = "FAILED_SUBMIT_LISTING_SPACE"
export const EDIT_LISTING_GUEST_ACCESS = "EDIT_LISTING_GUEST_ACCESS"
export const CLOSE_LISTING_GUEST_ACCESS = "CLOSE_LISTING_GUEST_ACCESS"
export const PENDING_SUBMIT_LISTING_GUEST_ACCESS = "PENDING_SUBMIT_LISTING_GUEST_ACCESS"
export const COMPLETE_SUBMIT_LISTING_GUEST_ACCESS = "COMPLETE_SUBMIT_LISTING_GUEST_ACCESS"
export const FAILED_SUBMIT_LISTING_GUEST_ACCESS = "FAILED_SUBMIT_LISTING_GUEST_ACCESS"
export const EDIT_LISTING_GUEST_INTERACTION = "EDIT_LISTING_GUEST_INTERACTION"
export const CLOSE_LISTING_GUEST_INTERACTION = "CLOSE_LISTING_GUEST_INTERACTION"
export const PENDING_SUBMIT_LISTING_GUEST_INTERACTION = "PENDING_SUBMIT_LISTING_GUEST_INTERACTION"
export const COMPLETE_SUBMIT_LISTING_GUEST_INTERACTION = "COMPLETE_SUBMIT_LISTING_GUEST_INTERACTION"
export const FAILED_SUBMIT_LISTING_GUEST_INTERACTION = "FAILED_SUBMIT_LISTING_GUEST_INTERACTION"
export const EDIT_LISTING_NEIGHBOURHOOD = "EDIT_LISTING_NEIGHBOURHOOD"
export const CLOSE_LISTING_NEIGHBOURHOOD = "CLOSE_LISTING_NEIGHBOURHOOD"
export const PENDING_SUBMIT_LISTING_NEIGHBOURHOOD = "PENDING_SUBMIT_LISTING_NEIGHBOURHOOD"
export const COMPLETE_SUBMIT_LISTING_NEIGHBOURHOOD = "COMPLETE_SUBMIT_LISTING_NEIGHBOURHOOD"
export const FAILED_SUBMIT_LISTING_NEIGHBOURHOOD = "FAILED_SUBMIT_LISTING_NEIGHBOURHOOD"
export const EDIT_LISTING_GETTING_AROUND = "EDIT_LISTING_GETTING_AROUND"
export const CLOSE_LISTING_GETTING_AROUND = "CLOSE_LISTING_GETTING_AROUND"
export const PENDING_SUBMIT_LISTING_GETTING_AROUND = "PENDING_SUBMIT_LISTING_GETTING_AROUND"
export const COMPLETE_SUBMIT_LISTING_GETTING_AROUND = "COMPLETE_SUBMIT_LISTING_GETTING_AROUND"
export const FAILED_SUBMIT_LISTING_GETTING_AROUND = "FAILED_SUBMIT_LISTING_GETTING_AROUND"
export const EDIT_LISTING_OTHER_THINGS = "EDIT_LISTING_OTHER_THINGS"
export const CLOSE_LISTING_OTHER_THINGS = "CLOSE_LISTING_OTHER_THINGS"
export const PENDING_SUBMIT_LISTING_OTHER_THINGS = "PENDING_SUBMIT_LISTING_OTHER_THINGS"
export const COMPLETE_SUBMIT_LISTING_OTHER_THINGS = "COMPLETE_SUBMIT_LISTING_OTHER_THINGS"
export const FAILED_SUBMIT_LISTING_OTHER_THINGS = "FAILED_SUBMIT_LISTING_OTHER_THINGS"
export const EDIT_LISTING_SEO_TITLE = "EDIT_LISTING_SEO_TITLE"
export const CLOSE_LISTING_SEO_TITLE = "CLOSE_LISTING_SEO_TITLE"
export const PENDING_SUBMIT_LISTING_SEO_TITLE = "PENDING_SUBMIT_LISTING_SEO_TITLE"
export const COMPLETE_SUBMIT_LISTING_SEO_TITLE = "COMPLETE_SUBMIT_LISTING_SEO_TITLE"
export const FAILED_SUBMIT_LISTING_SEO_TITLE = "FAILED_SUBMIT_LISTING_SEO_TITLE"
export const EDIT_LISTING_SEO_DESCRIPTION = "EDIT_LISTING_SEO_DESCRIPTION"
export const CLOSE_LISTING_SEO_DESCRIPTION = "CLOSE_LISTING_SEO_DESCRIPTION"
export const PENDING_SUBMIT_LISTING_SEO_DESCRIPTION = "PENDING_SUBMIT_LISTING_SEO_DESCRIPTION"
export const COMPLETE_SUBMIT_LISTING_SEO_DESCRIPTION = "COMPLETE_SUBMIT_LISTING_SEO_DESCRIPTION"
export const FAILED_SUBMIT_LISTING_SEO_DESCRIPTION = "FAILED_SUBMIT_LISTING_SEO_DESCRIPTION"
export const EDIT_LISTING_SEO_KEYWORDS = "EDIT_LISTING_SEO_KEYWORDS"
export const CLOSE_LISTING_SEO_KEYWORDS = "CLOSE_LISTING_SEO_KEYWORDS"
export const PENDING_SUBMIT_LISTING_SEO_KEYWORDS = "PENDING_SUBMIT_LISTING_SEO_KEYWORDS"
export const COMPLETE_SUBMIT_LISTING_SEO_KEYWORDS = "COMPLETE_SUBMIT_LISTING_SEO_KEYWORDS"
export const FAILED_SUBMIT_LISTING_SEO_KEYWORDS = "FAILED_SUBMIT_LISTING_SEO_KEYWORDS"
export const PENDING_SUBMIT_LISTING_ROOM_SPACES = "PENDING_SUBMIT_LISTING_ROOM_SPACES"
export const COMPLETE_SUBMIT_LISTING_ROOM_SPACES = "COMPLETE_SUBMIT_LISTING_ROOM_SPACES"
export const FAILED_SUBMIT_LISTING_ROOM_SPACES = "FAILED_SUBMIT_LISTING_ROOM_SPACES"
export const EDIT_LISTING_AMENITIES = "EDIT_LISTING_AMENITIES"
export const CLOSE_LISTING_AMENITIES = "CLOSE_LISTING_AMENITIES"
export const PENDING_SUBMIT_LISTING_AMENITIES = "PENDING_SUBMIT_LISTING_AMENITIES"
export const COMPLETE_SUBMIT_LISTING_AMENITIES = "COMPLETE_SUBMIT_LISTING_AMENITIES"
export const FAILED_SUBMIT_LISTING_AMENITIES = "FAILED_SUBMIT_LISTING_AMENITIES"
export const EDIT_LISTING_FACILITIES = "EDIT_LISTING_FACILITIES"
export const CLOSE_LISTING_FACILITIES = "CLOSE_LISTING_FACILITIES"
export const PENDING_SUBMIT_LISTING_FACILITIES = "PENDING_SUBMIT_LISTING_FACILITIES"
export const COMPLETE_SUBMIT_LISTING_FACILITIES = "COMPLETE_SUBMIT_LISTING_FACILITIES"
export const FAILED_SUBMIT_LISTING_FACILITIES = "FAILED_SUBMIT_LISTING_FACILITIES"
export const EDIT_LISTING_PROPERTY_TYPE = "EDIT_LISTING_PROPERTY_TYPE"
export const CLOSE_LISTING_PROPERTY_TYPE = "CLOSE_LISTING_PROPERTY_TYPE"
export const PENDING_SUBMIT_LISTING_PROPERTY_TYPE = "PENDING_SUBMIT_LISTING_PROPERTY_TYPE"
export const COMPLETE_SUBMIT_LISTING_PROPERTY_TYPE = "COMPLETE_SUBMIT_LISTING_PROPERTY_TYPE"
export const FAILED_SUBMIT_LISTING_PROPERTY_TYPE = "FAILED_SUBMIT_LISTING_PROPERTY_TYPE"
export const EDIT_LISTING_NUM_OF_GUESTS = "EDIT_LISTING_NUM_OF_GUESTS"
export const CLOSE_LISTING_NUM_OF_GUESTS = "CLOSE_LISTING_NUM_OF_GUESTS"
export const PENDING_SUBMIT_LISTING_NUM_OF_GUESTS = "PENDING_SUBMIT_LISTING_NUM_OF_GUESTS"
export const COMPLETE_SUBMIT_LISTING_NUM_OF_GUESTS = "COMPLETE_SUBMIT_LISTING_NUM_OF_GUESTS"
export const FAILED_SUBMIT_LISTING_NUM_OF_GUESTS = "FAILED_SUBMIT_LISTING_NUM_OF_GUESTS"
export const EDIT_LISTING_HOMEOWNER = "EDIT_LISTING_HOMEOWNER"
export const CLOSE_LISTING_HOMEOWNER = "CLOSE_LISTING_HOMEOWNER"
export const PENDING_SUBMIT_LISTING_HOMEOWNER = "PENDING_SUBMIT_LISTING_HOMEOWNER"
export const COMPLETE_SUBMIT_LISTING_HOMEOWNER = "COMPLETE_SUBMIT_LISTING_HOMEOWNER"
export const FAILED_SUBMIT_LISTING_HOMEOWNER = "FAILED_SUBMIT_LISTING_HOMEOWNER"
export const EDIT_LISTING_LOCATION = "EDIT_LISTING_LOCATION"
export const CLOSE_LISTING_LOCATION = "CLOSE_LISTING_LOCATION"
export const PENDING_SUBMIT_LISTING_LOCATION = "PENDING_SUBMIT_LISTING_LOCATION"
export const COMPLETE_SUBMIT_LISTING_LOCATION = "COMPLETE_SUBMIT_LISTING_LOCATION"
export const FAILED_SUBMIT_LISTING_LOCATION = "FAILED_SUBMIT_LISTING_LOCATION"
export const EDIT_LISTING_ADDITIONAL_RULES = "EDIT_LISTING_ADDITIONAL_RULES"
export const CLOSE_LISTING_ADDITIONAL_RULES = "CLOSE_LISTING_ADDITIONAL_RULES"
export const PENDING_SUBMIT_LISTING_ADDITIONAL_RULES = "PENDING_SUBMIT_LISTING_ADDITIONAL_RULES"
export const COMPLETE_SUBMIT_LISTING_ADDITIONAL_RULES = "COMPLETE_SUBMIT_LISTING_ADDITIONAL_RULES"
export const FAILED_SUBMIT_LISTING_ADDITIONAL_RULES = "FAILED_SUBMIT_LISTING_ADDITIONAL_RULES"
export const PENDING_SUBMIT_LISTING_DISABLE = "PENDING_SUBMIT_LISTING_DISABLE"
export const COMPLETE_SUBMIT_LISTING_DISABLE = "COMPLETE_SUBMIT_LISTING_DISABLE"
export const FAILED_SUBMIT_LISTING_DISABLE = "FAILED_SUBMIT_LISTING_DISABLE"