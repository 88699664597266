import React, {useState, useEffect} from 'react'
import {Grid} from "@material-ui/core"
import axios from "axios"
import {useDispatch, useSelector} from "react-redux"
import { Get_External_Booking, Publish_Feedback, Get_Booking, Get_Order, Modify_Booking, Download_Invoice, Send_Feedback_Link, Get_Feedback } from "../../api/private/index"
import httpClientPrivate, {httpListingClient} from "../../api/httpClientPrivate"
import Skeleton from '@material-ui/lab/Skeleton';
import moment from "moment"
import { useSnackbar } from 'notistack'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


function ExternalReservationDetail(props) {
    const {token} = useSelector(state => state.auth)
    const booking_id = props.match.params.booking_id
    const [pending_fetch, setpending_fetch] = useState(true)
    const [failed_fetch, setfailed_fetch] = useState(false)
    const [fetch_complete, setfetch_complete] = useState(false)
    const [fetched_booking, setfetched_booking] = useState(null)
    const [fetched_order, setfetched_order] = useState(null)
    const signal = axios.CancelToken.source()
    const [pendingsubmit, setpendingsubmit] = useState(false);
    const [completesubmit, setcompletesubmit] = useState(false);
    const [failedsubmit, setfailedsubmit] = useState(false);
    const [feedbackcall, setfeedbackcall] = useState(null)
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if(fetched_booking !== null) {
            if(fetched_booking.booking_status === "confirmed") {
                fetchingReview(fetched_booking.messaging_id)
            }
        }
    }, [fetched_booking])


    const fetchingBooking = (payload) => {
        setpending_fetch(true)
        setfailed_fetch(false)
        setfetch_complete(false)
        setfetched_booking(null)

        httpListingClient(Get_External_Booking + payload.booking_id, {}, "GET", payload.token, "", "", "v2")
        .then(res => {
            
            setpending_fetch(false)
            setfailed_fetch(false)
            setfetch_complete(true)
            setfetched_booking(res.data)
        })
        .catch(() => {
            setpending_fetch(false)
            setfailed_fetch(true)
            setfetch_complete(true)
            setfetched_booking(null)           
        })
    
    }


    const fetchingReview = (messaging_id) => {
        httpClientPrivate(Get_Feedback + messaging_id, {}, "GET", token)
        .then(res => {
            if(res.data.feedback === true) {
               
                setfeedbackcall(res.data)
            }
        })
    }

    const sendReviewLink = (booking_id) => {
        const payload = {
            "booking_id": booking_id
        }

        httpClientPrivate(Send_Feedback_Link, payload, "POST",token)
        .then(res => {
            if(res.data.status === 200) {

                enqueueSnackbar(
                    "A review link has been sent to the customer",
                    {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }
                    })
    
            } else {
                enqueueSnackbar(
                    res.data.remarks,
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }
                    })
            }
        })
    }

    const publishUnpublish = (messaging_id, val) => {
        const payload = {
            "messaging_id": messaging_id,
            "val":val
        }

        httpClientPrivate(Publish_Feedback, payload, "POST",token)
        .then(res => {
            if(res.data.status === 200) {
                const payload = {
                    "token": token,
                    "signal": signal.token,
                    "booking_id": booking_id
                }
        
                fetchingBooking(payload)

                enqueueSnackbar(
                    "Review Visibilty Changed",
                    {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }
                    })
    
            } else {
                enqueueSnackbar(
                    res.data.remarks,
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }
                    })
            }
        })
    }



    useEffect(() => {
        const payload = {
            "token": token,
            "signal": signal.token,
            "booking_id": booking_id
        }

        fetchingBooking(payload)
        return () => {
            signal.cancel()
        }
    // eslint-disable-next-line
    },[booking_id])

    useEffect(() => {
        /*if(failed_fetch) {
            props.history.push("/listings/404")
        }*/
    // eslint-disable-next-line
    },[failed_fetch])

    useEffect(() => {
        if(completesubmit && failedsubmit) {
            enqueueSnackbar(
                "Failed action",
                {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                })
        }

        if(completesubmit && !failedsubmit) {
            enqueueSnackbar(
                "Field updated successfully",
                {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                })
            
            
            
                const payload = {
                    "token": token,
                    "signal": signal.token,
                    "booking_id": booking_id
                }
        
                fetchingBooking(payload)
           

           
        }
    }, [failedsubmit, completesubmit])

    return (
        <div className="fixed-padding dashboard-wrapper">
            {fetched_booking === null && (
                                    <>
                                    <Skeleton animation="wave"  height={20}/>
                                    <Skeleton animation="wave"  height={20}/>
                                    <Skeleton animation="wave"  height={20}/>
                                    </>
                                )}
            {fetched_booking !== null && (
                        <>
                        <Grid container>
                            <Grid item xs={6}>
                                <h1>Reservation</h1>
                            </Grid>
                        </Grid>
                        
                            <div className="booking-sample">
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <div className="booking-id">#{fetched_booking.booking_id}</div>
                                    </Grid>
                                    <Grid item>
                                        <div style={fetched_booking.booking_status === "confirmed" && {background: "#38cb00"} ||
                                                    fetched_booking.booking_status === "waiting_payment" && {background: "#dec600"} ||
                                                    fetched_booking.booking_status === "cancelled" && {background: "red"}} className="booking-status">{fetched_booking.booking_status}</div>
                                    </Grid>
                                    <Grid item>
                                        <div className="booking-id">{fetched_booking.booking_source}</div>
                                    </Grid>
                                    <Grid item>
                                        {fetched_booking.booking_status === "cancelled" && (
                                            fetched_booking.booking_refund === "refunded" && (
                                                <div className="booking-id">Refunded</div>
                                            )
                                        )}
                                    </Grid>
                                </Grid>
                                <div className="section">
                                
                                <div className="sub-section">
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <div className="billed_profile">Billed to: {fetched_booking.billed_to}</div>
                                    </Grid>
                                    <Grid item>
                                        <div className="billed_profile">Billed phone: {fetched_booking.billed_to_phone}</div>
                                    </Grid>
                                    <Grid item>
                                        <div className="billed_profile">Billed email: {fetched_booking.billed_to_email}</div>
                                    </Grid>
                                    <Grid item>
                                        <div className="billed_profile">Booked on: {moment(fetched_booking.time_stamp).format("DD MMMM YYYY hh:mm A")}</div>
                                    </Grid>
                                </Grid>
                                </div>
                                </div>
                                <div className="section">
                                    <div className="sub-section">
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <div className="booking-detail time-line">
                                           From: {moment(fetched_booking.date_from).format("DD MMMM YYYY")}
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="booking-detail time-line">
                                            To: {moment(fetched_booking.date_to).format("DD MMMM YYYY")}
                                        </div>
                                    </Grid>
                                    
                                </Grid>
                                </div>
                                <div className="sub-section">
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <div className="booking-detail">
                                                Number of Guests: {fetched_booking.num_guests}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className="booking-detail">
                                                Number of Children: {fetched_booking.children}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className="booking-detail">
                                                Nights booked: {fetched_booking.days_to_booked}
                                            </div>
                                        </Grid>
                                        
                                    </Grid>
                                </div>
                                </div>
                                <div class="section">
                                <Grid className="bill-header" container spacing={2}>
                                    <Grid item xs={4}>Rental</Grid>
                                    <Grid item xs={1}>No. of Nights</Grid>
                                    <Grid item xs={2}>Avg. price / night</Grid>
                                    <Grid item xs={2}>Price</Grid>
                                </Grid>
                                <Grid container className="bill-body" spacing={2}>
                                    <Grid item xs={4}><div className="prop-title">{fetched_booking.property_name}</div></Grid>
                                    <Grid item xs={1}>{fetched_booking.days_to_booked}</Grid>
                                    <Grid item xs={2}>{fetched_booking.per_night_average}</Grid>
                                    <Grid item xs={2}>INR {fetched_booking.base_amount}</Grid>
                                </Grid>
                                {[fetched_booking.per_night_average <= 7499 ? 6 : 9, fetched_booking.per_night_average <= 7499 ? 6 : 9].map(tax => (
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}>{tax}%</Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={2}>INR {((fetched_booking.base_amount/100)*tax)}</Grid>
                                    </Grid>
                                ))}
                                <Grid container className="bill-body" spacing={2}>
                                    <Grid item xs={4}>
                                        Total taxes
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2}>INR {fetched_booking.grand_total_taxes}</Grid>
                                </Grid>
                                <Grid container style={{fontSize: "1.5rem"}} spacing={2}>
                                    <Grid item xs={4}>
                                       Grand Total
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2}>INR {fetched_booking.grand_total_amount}</Grid>
                                </Grid>
                                </div>
                                
                                
  

                               

                                {feedbackcall !== null && (
                                    <div className="customer-feedback">
                                        <div className="customer-feedback-header">
                                            Feedback from Customer
                                        </div>
                                        <Grid container style={{alignItems: "center", gap:"40px"}}>
                                            <Grid item>
                                                <div className="customer-rated">
                                                    
                                                    <span className="overall-rating">{feedbackcall.home_overall_rating}/5</span>
                                                    <br/>
                                                    <span className="overall-caption-rating">Overall</span>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <ul>
                                                    <li>Comfort Rating: {feedbackcall.home_comfort_rating}</li>
                                                    <li>Design Rating: {feedbackcall.home_design_rating}</li>
                                                    <li>Location Rating: {feedbackcall.home_location_rating}</li>
                                                    <li>Service Rating: {feedbackcall.home_service_rating}</li>
                                                </ul>
                                            </Grid>
                                            <Grid item>
                                                <div className="what-user-thinks">
                                                    <p className="q">Things our guest liked and disliked:</p>
                                                    <p>{feedbackcall.home_service_feedback}</p>
                                                    <br/>
                                                    <p className="q">Guest said about location:</p>
                                                    <p>{feedbackcall.home_location_feedback}</p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className="publish-review">
                                            <Grid container>
                                                <Grid item>
                                                    <button onClick={() => publishUnpublish(fetched_booking[0].messaging_id, !feedbackcall.publish)}>{feedbackcall.publish === false ? "Publish Review" : "Unpublish"}</button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                )}
                                
                                
                            </div>
                        </>
                    
            )}
            
        </div>
    )
}

export default ExternalReservationDetail
