import React from 'react'
import { Grid, Hidden, IconButton } from '@material-ui/core'
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
function Logoutnav() {
    const handleMenuLogout = () => {
        console.log("menu")
    }
    return (
        <Grid item xs={2} sm={4} lg={5}>
            <Grid container>
                <Hidden smUp>
                    <div className="btn btn-menu">
                        <IconButton
                            aria-label="notification"
                            aria-controls="notification-menu"
                            aria-haspopup="true"
                            onClick={handleMenuLogout}
                            size="medium"
                        >
                            <MenuOpenRoundedIcon />
                        </IconButton>
                    </div>
                </Hidden>
            </Grid>
            <Grid container justify="flex-end">
                <Hidden xsDown>
                    <div className="list list-menu">
                        <Grid container spacing={2}>
                            <Grid item>Documentation</Grid>
                            <Grid item>Home</Grid>
                        </Grid>
                    </div>
                </Hidden>
            </Grid>
        </Grid>
    )
}

export default Logoutnav
