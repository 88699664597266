import { OPEN_NOTIFICATION_MENU, CLOSE_NOTIFICATION_MENU, FETCHED_NOTIFICATION, FETCHING_NOTIFICATION, FAILED_FETCH_NOTIFICATION, NOTIFICATION_NEW_INDICATOR } from "./notificationTypes"
import httpClient from "../../api/httpClientPrivate";
import { Get_Notification } from "../../api/private/index"
import moment from "moment"

export const openNotificationMenu = (payload) => {
    return {
        type: OPEN_NOTIFICATION_MENU,
        payload: payload
    }
}

export const closeNotificationMenu = () => {
    return {
        type: CLOSE_NOTIFICATION_MENU
    }
}

export const fetchingNotification = () => {
    return {
        type: FETCHING_NOTIFICATION
    }
}

export const fetchingFailed = () => {
    return {
        type: FAILED_FETCH_NOTIFICATION
    }
}

export const fetchedNotification = (payload) => {
    return {
        type: FETCHED_NOTIFICATION,
        payload: payload
    }
}

export const newNotificationIndicator = () => {
    return {
        type: NOTIFICATION_NEW_INDICATOR
    }
}


export const getNotificationsServer = (payload) => dispatch => {
    dispatch(fetchingNotification())

    httpClient(Get_Notification, {}, "GET", payload.token, payload.signal_token)
        .then(res => {

            const notificationdtx = localStorage.getItem('notificationdtx')

            if (res.data.length !== 0) {
                if (notificationdtx !== null) {
                    if (moment(res.data[0].timestamp).isAfter(notificationdtx)) {
                        dispatch(newNotificationIndicator())
                        console.log("new data came")
                    }
                } else {
                    localStorage.setItem('notificationdtx', res.data[0].timestamp)
                    dispatch(newNotificationIndicator())
                }
            }

            dispatch(fetchedNotification({ "notification_data": res.data }))
        })
        .catch(res => {
            dispatch(fetchingFailed())
        })

}