import {CLOSE_EDIT_LISTING_TITLE, EDIT_LISTING_TITLE, PENDING_SUBMIT_LISTING_TITLE, COMPLETE_SUBMIT_LISTING_TITLE, FAILED_SUBMIT_LISTING_TITLE} from "./mlTypes"

const initialState = {
    pendingsubmit: false,
    failedsubmit: false,
    completesubmit: false,
    propertytitleediting: false
}

const mlListingTitleReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_LISTING_TITLE: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertytitleediting: true
        }

        case CLOSE_EDIT_LISTING_TITLE: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertytitleediting: false
        }

        case PENDING_SUBMIT_LISTING_TITLE: return {
            ...state,
            pendingsubmit: true,
            failedsubmit: false,
            completesubmit: false
        }

        case COMPLETE_SUBMIT_LISTING_TITLE: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: true
        }

        case FAILED_SUBMIT_LISTING_TITLE: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: true,
            completesubmit: true
        }

    
        default: return state
    }
}

export default mlListingTitleReducer