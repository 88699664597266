import { LOGIN, AUTH_ERROR, LOGGEDIN } from "./authTypes";
import httpClient from "../../api/httpClient";
import { Public_Login } from "../../api/public/index";

/**
 * All synchronized and asynchronized actions for the reducers
 * are mentioned here. Synchronized actions are mentioned in the top 
 * and is separated with another code comment below.
 * 
 * Synchronized actions return a statement/object with a type and sometimes a payload.
 * They are used in combination with asynchronized actions.
 * 
 */

/**
 * All Authentication related actions are mentioned here
 */

/**
 * Synchronized actions start from here.
 */


/**
 * AttemptLogin is dispatched when user attempts to Login.
 */

export const AttemptLogin = () => {
    return {
        type: LOGIN
    }
}

/**
 * 
 * LoggedIn is dispatched when the user has successfully logged in.
 * The Payload it receives are "username", "token" and "user_level"
 */

export const LoggedIn = (payload) => {
    return {
        type: LOGGEDIN,
        payload: payload
    }
}

/**
 * 
 * AuthError is dispatched when the log-in attempt results
 * in an error/failure.
 * It receives the error msg as the payload.
 */

export const AuthError = payload => {
    return {
        type: AUTH_ERROR,
        payload: payload
    }
}

/**
 * Asynchronized actions start from here. Uses thunk middleware.
 */

/**
 * This action is dispatched from the Auth Page after someone submits the
 * login form. 
 * 
 * It receives a payload of "username" and "password" along with a 
 * dispatch method.
 * 
 * The logical progression of Login occurs here.
 * Where the first dispatch call for attempt login.
 * 
 * httpClient uses a preset api along with a payload and a
 * REST method to call the server.
 * 
 * Other synchronized dispatch are called based on the server response.
 * 
 */

export const Login = (payload) => dispatch => {
    dispatch(AttemptLogin());

    return Promise.resolve(
        httpClient(Public_Login, payload, "POST")
            .then(res => {
                if (res.data.status === 200) {

                    const token = res.headers.token;

                    const payloadDispatch = {
                        "token": token,
                        "username": res.data.email,
                        "name": res.data.name,
                        "user_level": res.data.user_level,
                        "img": res.data.img
                    }

                    dispatch(LoggedIn(payloadDispatch))

                } else {

                    const AuthErrorLoad = { "error_msg": res.data.remarks, "error_code": res.data.status }
                    dispatch(AuthError(AuthErrorLoad))

                }
            })
            .catch(res => {

                const AuthErrorLoad = { "error_msg": `Server Hang up, please try again later. [${res.response !== undefined && res.response.status}]`, "error_code": res.response !== undefined && res.response.status }
                dispatch(AuthError(AuthErrorLoad))

            })

    );

}