import {FAILED_MULTIPLE_IMAGE_UPLOAD,COMPLETE_MULTIPLE_IMAGE_UPLOAD, PENDING_MULTIPLE_IMAGE_UPLOAD, OPEN_MANY_PHOTO_CHANGE, CLOSE_MANY_PHOTO_CHANGE, PENDING_PHOTO_DELETE, COMPLETE_PHOTO_DELETE, FAILED_PHOTO_DELETE, PENDING_PHOTO_POSITION_CHANGE,COMPLETE_PHOTO_POSITION_CHANGE,FAILED_PHOTO_POSITION_CHANGE, PENDING_PHOTO_SUBMIT, COMPLETE_PHOTO_SUBMIT, FAILED_PHOTO_SUBMIT, OPEN_COVER_PHOTO_CHANGE, CLOSE_COVER_PHOTO_CHANGE} from "./mlTypes"

const initialState = {
    "cover_photo_open": false,
    "upload_many_open": false,
    "photo_updating": false,
    "photo_updated": false,
    "allphotos_pending_change_pos": false,
    "allphotos_complete_change_pos": false,
    "allphotos_failed_change_pos": false,
    "photo_delete_pending": false,
    "photo_delete_complete": false,
    "photo_delete_failed": false,
    "multiphoto_updating": false,
    "multiphoto_updated": false,
    "multiphoto_update_fail": false
}

const mlPhotoReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_COVER_PHOTO_CHANGE: return {
            ...state,
            cover_photo_open: true
        }
        
        case CLOSE_COVER_PHOTO_CHANGE: return {
            ...state,
            cover_photo_open: false
        }

        case OPEN_MANY_PHOTO_CHANGE: return {
            ...state,
            upload_many_open: true
        }

        case CLOSE_MANY_PHOTO_CHANGE: return {
            ...state,
            upload_many_open: false
        }

        case PENDING_PHOTO_SUBMIT: return {
            ...state,
            photo_updating: true,
            photo_updated: false
        }

        case PENDING_MULTIPLE_IMAGE_UPLOAD: return {
            ...state,
            multiphoto_updating: true,
            multiphoto_updated: false,
            multiphoto_update_fail: false
        }

        case COMPLETE_PHOTO_SUBMIT: return {
            ...state,
            photo_updating: false,
            photo_updated: true
        }

        case COMPLETE_MULTIPLE_IMAGE_UPLOAD: return {
            ...state,
            multiphoto_updated: true,
            multiphoto_updating: false,
            multiphoto_update_fail: false
        }

        case FAILED_PHOTO_SUBMIT: return {
            ...state,
            photo_updating: false,
            photo_updated: false
        }

        case FAILED_MULTIPLE_IMAGE_UPLOAD: return {
            ...state,
            multiphoto_updating: false,
            multiphoto_updated: false,
            multiphoto_update_fail: true
        }

        case PENDING_PHOTO_POSITION_CHANGE: return {
            ...state,
            allphotos_pending_change_pos: true,
            allphotos_complete_change_pos: false,
            allphotos_failed_change_pos: false
        }

        case COMPLETE_PHOTO_POSITION_CHANGE: return {
            ...state,
            allphotos_pending_change_pos: false,
            allphotos_complete_change_pos: true,
            allphotos_failed_change_pos: false
        }

        case FAILED_PHOTO_POSITION_CHANGE: return {
            ...state,
            allphotos_pending_change_pos: false,
            allphotos_complete_change_pos: true,
            allphotos_failed_change_pos: true
        }

        case PENDING_PHOTO_DELETE: return {
            ...state,
            photo_delete_pending: true,
            photo_delete_complete: false,
            photo_delete_failed: false
        }

        case COMPLETE_PHOTO_DELETE: return {
            ...state,
            photo_delete_complete: true,
            photo_delete_pending: false,
            photo_delete_failed: false
        }

        case FAILED_PHOTO_DELETE: return {
            ...state,
            photo_delete_pending: false,
            photo_delete_complete: true,
            photo_delete_failed: true
        }
    
        default: return state
    }
}

export default mlPhotoReducer