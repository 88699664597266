import {MD_FAILED_MULTIPLE_IMAGE_UPLOAD,
    MD_COMPLETE_MULTIPLE_IMAGE_UPLOAD,
    MD_PENDING_MULTIPLE_IMAGE_UPLOAD,
    MD_OPEN_MANY_PHOTO_CHANGE,
    MD_CLOSE_MANY_PHOTO_CHANGE,
    MD_PENDING_PHOTO_DELETE,
    MD_COMPLETE_PHOTO_DELETE,
    MD_FAILED_PHOTO_DELETE, 
    MD_PENDING_PHOTO_POSITION_CHANGE,
    MD_COMPLETE_PHOTO_POSITION_CHANGE,
    MD_FAILED_PHOTO_POSITION_CHANGE,
    MD_PENDING_PHOTO_SUBMIT,
    MD_COMPLETE_PHOTO_SUBMIT,
    MD_FAILED_PHOTO_SUBMIT,
    MD_OPEN_COVER_PHOTO_CHANGE,
    MD_CLOSE_COVER_PHOTO_CHANGE} from "./mdTypes"

const initialState = {
    "cover_photo_open": false,
    "upload_many_open": false,
    "photo_updating": false,
    "photo_updated": false,
    "allphotos_pending_change_pos": false,
    "allphotos_complete_change_pos": false,
    "allphotos_failed_change_pos": false,
    "photo_delete_pending": false,
    "photo_delete_complete": false,
    "photo_delete_failed": false,
    "multiphoto_updating": false,
    "multiphoto_updated": false,
    "multiphoto_update_fail": false
}

const mdPhotoReducer = (state = initialState, action) => {
    switch (action.type) {
        case MD_OPEN_COVER_PHOTO_CHANGE: return {
            ...state,
            cover_photo_open: true
        }
        
        case MD_CLOSE_COVER_PHOTO_CHANGE: return {
            ...state,
            cover_photo_open: false
        }

        case MD_OPEN_MANY_PHOTO_CHANGE: return {
            ...state,
            upload_many_open: true
        }

        case MD_CLOSE_MANY_PHOTO_CHANGE: return {
            ...state,
            upload_many_open: false
        }

        case MD_PENDING_PHOTO_SUBMIT: return {
            ...state,
            photo_updating: true,
            photo_updated: false
        }

        case MD_PENDING_MULTIPLE_IMAGE_UPLOAD: return {
            ...state,
            multiphoto_updating: true,
            multiphoto_updated: false,
            multiphoto_update_fail: false
        }

        case MD_COMPLETE_PHOTO_SUBMIT: return {
            ...state,
            photo_updating: false,
            photo_updated: true
        }

        case MD_COMPLETE_MULTIPLE_IMAGE_UPLOAD: return {
            ...state,
            multiphoto_updated: true,
            multiphoto_updating: false,
            multiphoto_update_fail: false
        }

        case MD_FAILED_PHOTO_SUBMIT: return {
            ...state,
            photo_updating: false,
            photo_updated: false
        }

        case MD_FAILED_MULTIPLE_IMAGE_UPLOAD: return {
            ...state,
            multiphoto_updating: false,
            multiphoto_updated: false,
            multiphoto_update_fail: true
        }

        case MD_PENDING_PHOTO_POSITION_CHANGE: return {
            ...state,
            allphotos_pending_change_pos: true,
            allphotos_complete_change_pos: false,
            allphotos_failed_change_pos: false
        }

        case MD_COMPLETE_PHOTO_POSITION_CHANGE: return {
            ...state,
            allphotos_pending_change_pos: false,
            allphotos_complete_change_pos: true,
            allphotos_failed_change_pos: false
        }

        case MD_FAILED_PHOTO_POSITION_CHANGE: return {
            ...state,
            allphotos_pending_change_pos: false,
            allphotos_complete_change_pos: true,
            allphotos_failed_change_pos: true
        }

        case MD_PENDING_PHOTO_DELETE: return {
            ...state,
            photo_delete_pending: true,
            photo_delete_complete: false,
            photo_delete_failed: false
        }

        case MD_COMPLETE_PHOTO_DELETE: return {
            ...state,
            photo_delete_complete: true,
            photo_delete_pending: false,
            photo_delete_failed: false
        }

        case MD_FAILED_PHOTO_DELETE: return {
            ...state,
            photo_delete_pending: false,
            photo_delete_complete: true,
            photo_delete_failed: true
        }
    
        default: return state
    }
}

export default mdPhotoReducer