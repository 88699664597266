import React, { useEffect, useState } from 'react'
import { Route, Redirect } from "react-router-dom";
import { PATH_LIST } from "../roles/index"
import Cookies from "universal-cookie";
import { AES, enc } from "crypto-js";
import { OBX_KEY, COOKIE_AUTH_NAME } from "../config";
import { useDispatch, useSelector } from 'react-redux';
import { LoggedIn, AuthError } from "../redux/auth/authActions";
import { Fallback } from "../pages/static/index"

const cookies = new Cookies();


export default function Protected({ component: Component, path, ...args }) {
    const dispatch = useDispatch();
    const { logged_in } = useSelector(state => state.auth);
    const token = cookies.get(COOKIE_AUTH_NAME);
    const [checking, checked] = useState(false);
    
    useEffect(() => {
        if (token !== undefined) {

            if (logged_in) {
                checked(true)
                return
            }

            const bytes = AES.decrypt(token, OBX_KEY)
            const decipher = bytes.toString(enc.Utf8)

            if (decipher === "") {
                const AuthErrorLoad = { "error_msg": "You cannot access protected pages.", "error_code": "" }
                dispatch(AuthError(AuthErrorLoad))
            } else {
                const data = JSON.parse(decipher)

                if (PATH_LIST[path].includes(data.user_level)) {
                    dispatch(LoggedIn(data))
                } else {
                    const AuthErrorLoad = { "error_msg": "Your user account cannot access these pages.", "error_code": "" }
                    dispatch(AuthError(AuthErrorLoad))
                }

            }


        } else {
            const AuthErrorLoad = { "error_msg": "You cannot access protected pages.", "error_code": "" }
            dispatch(AuthError(AuthErrorLoad))
        }

        checked(true)
    // eslint-disable-next-line    
    }, [token])




    return (
        <>
            {checking ?
                <Route
                    {...args}
                    render={props => {
                        if (logged_in && logged_in) {
                            return <Component val="k" {...props} />
                        } else {
                            return <Redirect to="/auth" />
                        }
                    }}
                />
                : <Fallback />}
        </>
    )
}
