import { LOGIN, LOGGEDIN, AUTH_ERROR } from './authTypes';

/**
 * This is a reducer for all Authentication processes.
 */


/**
 * An initalState is set.
 * State of logged_in changes when logged in.
 * Token, user_level and username is filled when logged in.
 * 
 * error object is filled when Authentication fails.
 * 
 * attempt_login monitors the state of if attempting to login and if error occured.
 * attempts monitor the state for the number of attempts made in a single session.
 * 
 */


const initialState = {
    "username": "",
    "user_level": "",
    "token": "",
    "name": "",
    "img": "",
    "logged_in": false,
    "error": { "error_msg": "", "error_code": "" },
    "attempt_login": {
        "attempts": 0,
        "logging_in": false,
        "error": false
    }
}

const AuthReducer = (state = initialState, action) => {

    switch (action.type) {

        case LOGIN: return {
            ...state,
            "logged_in": false,
            "attempt_login": {
                attempts: state.attempt_login.attempts + 1,
                logging_in: true,
                error: false
            }
        }

        case LOGGEDIN: return {
            ...state,
            "token": action.payload.token,
            "username": action.payload.username,
            "user_level": action.payload.user_level,
            "name": action.payload.name,
            "img": action.payload.img,
            "logged_in": true,
            "attempt_login": {
                attempts: state.attempt_login.attempts,
                logging_in: false,
                error: false
            }
        }

        case AUTH_ERROR: return {
            ...state,
            "error": {
                "error_msg": action.payload.error_msg,
                "error_code": action.payload.error_code
            },
            "attempt_login": {
                "attempts": state.attempt_login.attempts,
                "logging_in": false,
                "error": true
            }
        }

        default: return state
    }
}

export default AuthReducer