import {PENDING_FETCH_LISTING, SUCCESS_FETCH_LISTING, FAILED_FETCH_LISTING} from "./mlTypes"

const initialState = {
    "pending_fetch": true,
    "failed_fetch": false,
    "fetch_complete": false,
    "fetched_listing": null
}

const mlReducer = (state= initialState, action) => {
    switch (action.type) {
        case PENDING_FETCH_LISTING: return {
            ...state,
            "pending_fetch": true,
            "failed_fetch": false,
            "fetch_complete": false,
            "fetched_listing": null
        }

        case SUCCESS_FETCH_LISTING: return {
            ...state,
            "pending_fetch": false,
            "failed_fetch": false,
            "fetch_complete": true,
            "fetched_listing": action.payload
        }

        case FAILED_FETCH_LISTING: return {
            ...state,
            "pending_fetch": false,
            "failed_fetch": true,
            "fetch_complete": true,
            "fetched_listing": null
        }
    
        default: return state
    }
}

export default mlReducer