import { 
         PENDING_SUBMIT_LISTING_ROOM_SPACES,
         COMPLETE_SUBMIT_LISTING_ROOM_SPACES,
         FAILED_SUBMIT_LISTING_ROOM_SPACES
    } from "./mlTypes"


const initialState = {
    pendingsubmit: false,
    failedsubmit: false,
    completesubmit: false
}


const mlListingRoomSpaces = (state = initialState, action) => {
    switch (action.type) {

        case PENDING_SUBMIT_LISTING_ROOM_SPACES: return {
            ...state,
            pendingsubmit: true,
            failedsubmit: false,
            completesubmit: false
        }

        case COMPLETE_SUBMIT_LISTING_ROOM_SPACES: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: true
        }
        
        case FAILED_SUBMIT_LISTING_ROOM_SPACES: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: true,
            completesubmit: true
        }
    
        default: return state
    }
}

export default mlListingRoomSpaces