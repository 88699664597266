import React from 'react'
import { MenuItem, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { useSelector } from 'react-redux'
import { FallbackNotification } from "../pages/static/index"
import { IconNotification, ContextSwitcher } from "./notificationcontent"

const useStyles = makeStyles((theme) => ({
    new: {
        background: "rgb(241 240 240)",
    },
    old: {
        background: "#fff"
    },
    contact: {
        color: "#00bc20"
    },
}));

function Notificationitems() {
    const { notification } = useSelector(state => state.notification);
    const classes = useStyles();
    const notificationdtx = localStorage.getItem('notificationdtx')

    return (
        <>
            {notification.notification_data && notification.notification_data.length !== 0 ? (

                notification.notification_data && notification.notification_data.map((item, idx) => (

                    <MenuItem key={idx} className={moment(item.timestamp).isAfter(notificationdtx) ? classes.new : classes.old}>
                        <div className="notification-item-elem">
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={2}>
                                    <div className="icon-item-notification">
                                        <IconNotification notificationtype={item.notification_type} img={item.property_img} />
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <ContextSwitcher notification_type={item.notification_type} item={item} />
                                    <p className="time-ago-notification-item-elem">{moment(item.timestamp).fromNow()}</p>
                                </Grid>
                            </Grid>
                        </div>
                    </MenuItem>

                ))

            ) : (
                    <FallbackNotification />
                )
            }
        </>
    )
}

export default Notificationitems
