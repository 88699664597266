import React, {useState} from 'react'
import { Grid, Hidden, IconButton } from '@material-ui/core'
import NotificationBtn from "./notification"
import ProfileIco from "./profileicon"
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import {NavLink, Link} from "react-router-dom"
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

function Loggedinnav() {
    const [anchor, setanchor] = useState(null);
    const [type, settype] = useState(null);

    const handleMenuLogout = () => {
        console.log("menu")
    }

    const dropDownHandler = (e, type) => {
        if(anchor !== e.currentTarget) {
            setanchor(e.currentTarget)
            settype(type)
        }
        
    }

    const handleCloseDropDown = () => {
        setanchor(null);
    }

    return (
        <>
            <Grid item xs={2} sm={4} lg={6}>
                <Grid container>
                    <Hidden smUp>
                        <div className="btn btn-menu">
                            <IconButton
                                aria-label="notification"
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                onClick={handleMenuLogout}
                                size="medium"
                            >
                                <MenuOpenRoundedIcon />
                            </IconButton>
                        </div>
                    </Hidden>
                </Grid>
                <Grid container justify="flex-end">
                    <Hidden xsDown>
                        <div className="list list-menu">
                            <Grid container spacing={2}>
                                <Grid item><NavLink exact className="list-menu-item" activeClassName="list-menu-item-active" to="/">Home</NavLink></Grid>
                                <Grid item><NavLink exact className="list-menu-item" activeClassName="list-menu-item-active" to="/listings">Listings</NavLink></Grid>
                                <Grid item><NavLink exact className="list-menu-item" activeClassName="list-menu-item-active" to="/calendar">Calendar</NavLink></Grid>
                                <Grid item><NavLink exact className="list-menu-item" activeClassName="list-menu-item-active" onMouseOver={(e) => {dropDownHandler(e,"users")}} to="/users">Users</NavLink></Grid>
                                <Grid item><NavLink exact className="list-menu-item" activeClassName="list-menu-item-active" onMouseOver={(e) => {dropDownHandler(e,"reservations")}} to="/users">Reservations</NavLink></Grid>
                                <Grid item><NavLink exact className="list-menu-item" activeClassName="list-menu-item-active" to="/manage-misc">Manage</NavLink></Grid>
                                <Grid item><NavLink exact className="list-menu-item" activeClassName="list-menu-item-active" to="/performance">Performance</NavLink></Grid>
                            </Grid>
                            <Menu
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                anchorEl={anchor}
                                open={Boolean(anchor)}
                                onClose={handleCloseDropDown}
                                MenuListProps={{ onMouseLeave: handleCloseDropDown }}
                                getContentAnchorEl={null}
                            >   
                                {
                                    type !== null && type === "users" && (
                                        <>
                                        <MenuItem><Link className="dropdown-link" to="/users/add">Add special users</Link></MenuItem>
                                        <MenuItem><Link className="dropdown-link" to="/users/list">View guest users</Link></MenuItem>
                                        <MenuItem><Link className="dropdown-link" to="/special/list">View special users</Link></MenuItem>
                                        </>
                                    )

                                }

                                {
                                    type !== null && type === "reservations" && (
                                        <>
                                        <MenuItem><Link className="dropdown-link" to="/reservations">Reservations</Link></MenuItem>
                                        <MenuItem><Link className="dropdown-link" to="/past_reservations">Past Reservations</Link></MenuItem>
                                        <MenuItem><Link className="dropdown-link" to="/external_reservations">External Reservations</Link></MenuItem>
                                        <MenuItem><Link className="dropdown-link" to="/reservation/booking_requests">Booking enquires</Link></MenuItem>
                                        <MenuItem><Link className="dropdown-link" to="/reservation/all_booking_requests">All Booking enquires</Link></MenuItem>
                                        <MenuItem><Link className="dropdown-link" to="/reservation/cancelled_bookings">Cancelled bookings</Link></MenuItem>
                                        </>
                                    )
                                }

                                {
                                    type !== null && type === "manage" && (
                                        <>
                                            <MenuItem>Amenities & Facilities</MenuItem>
                                            <MenuItem>Location</MenuItem>
                                            <MenuItem>Tax</MenuItem>
                                            <MenuItem>GST location</MenuItem>
                                            <MenuItem>OTA Calendar Sync</MenuItem>
                                            <MenuItem>Labels & Testimonials</MenuItem>
                                            <MenuItem>Promotions</MenuItem>
                                        </>
                                    ) 
                                }
                                
                            </Menu>
  
                        </div>
                    </Hidden>
                </Grid>
            </Grid>
            <Grid item xs={2} sm={1} lg={1}>
                <Grid container justify="center">
                    <Grid item>
                        <NotificationBtn />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2} sm={1} lg={1}>
                <Grid container justify="center">
                    <Grid item>
                        <ProfileIco />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Loggedinnav
