import React from 'react'
import { Grid } from '@material-ui/core'
import moment from 'moment'

function footer() {
    /**
     * Footer content for all routes.
     * 
     */


    return (
        <div className="footer-container">
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                    <div className="footer-content">
                        <p>© {moment().year()} Roamhome</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default footer
