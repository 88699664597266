import React, { useEffect } from 'react'
import LayoutWrapper from './layout'
import Routes from './routes/routes'
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { useSelector, useDispatch } from "react-redux";
import { lightTheme } from "./themes/light"
import { establishConnection, disconnectConnection, newNotification } from "./redux/socket/socketActions"
import { WEBSOCK } from "./config"
import { BrowserRouter } from 'react-router-dom'
/**
 * Root Html Dom for App.
 * Dark and Light themes are added here for future uses.
 * Material UI with lightTheme as default is provided down the
 * layout and to all other components.
 * 
 * Snackbar is wrapped around all the components to provide. notistack
 * (notification popups) provide a hook like way of rendering MUI Snacks.
 * 
 * Routes are invoked as a children to the main layout component.
 * Centralized Socket.io initialization.
 */



function App() {
  const { logged_in, username, name } = useSelector(state => state.auth);
  const { socket, socket_connected } = useSelector(state => state.sock);
  const dispatch = useDispatch();

  /**
   * Starts a new Socket.io instance if logged in or
   * if connection status changes.
   * 
   */

  useEffect(() => {
    if (logged_in && socket_connected === false) {
      dispatch(establishConnection({ url: WEBSOCK }))
    }
    // eslint-disable-next-line
  }, [logged_in, socket_connected])


  /**
   * Listens to disconnection of socket.
   * 
   */

  useEffect(() => {
    if (socket !== null) {

      socket.on("disconnect", () => {
        dispatch(disconnectConnection(socket))
      })
    }
    // eslint-disable-next-line
  }, [socket])


  /**
   * 
   * If Socket is connected then assign the current
   * logged in user to the web socket server.
   * 
   * 
   */

  useEffect(() => {
    if (socket_connected) {
      socket.emit('connectAdmins', { "user": username, "name": name, "user_id": socket.id })

    }
    return () => {
      if (socket_connected) {
        dispatch(disconnectConnection(socket))
      }
    }
    // eslint-disable-next-line
  }, [socket_connected])


  /**
   * 
   * Listen to updates/events coming from server
   * And dispatch notifications and redux state mutation
   * based on the type of event.
   * 
   */

  useEffect(() => {
    if (socket_connected) {
      socket.on("allUsers", (msg) => {
        console.log(msg)
      })

      socket.on("notification", (msg) => {
        console.log(msg)
        let payload = {}
        switch (msg.type) {
          case "host_request":
            payload = {
              "notification_msg": `New request to host from ${msg.val.name} (${msg.val.phone}). Please check your email.`,
              "notification_type": msg.type
            }
            dispatch(newNotification(payload))
            break;
          case "contact_request":
            payload = {
              "notification_msg": `We have a new Query from ${msg.val.name} (${msg.val.phone}). Please respond on email.`,
              "notification_type": msg.type
            }
            dispatch(newNotification(payload))
            break;
          case "booking_request":
            payload = {
              "notification_msg": `New Booking Request from ${msg.data.billed_to} (${msg.data.phone}).`,
              "notification_type": msg.type
            }
            dispatch(newNotification(payload))
            break;
          case "booking":
            payload = {
              "notification_msg": "We have received a new Booking.",
              "notification_type": msg.type
            }
            dispatch(newNotification(payload))
            break;
          case "cancellation":
              payload = {
                "notification_msg": "We have a cancellation.",
                "notification_type": msg.type
              }
              dispatch(newNotification(payload))
              break;
          case "external_bookinng":
            payload = {
              "notification_msg": `We have a ${msg.data.booking_status === "confirmed" ? "booking" : "cancellation"} from ${msg.data.booking_source} by ${msg.data.name} for ${msg.data.property_name}.`,
              "notification_type": msg.type
            }
            dispatch(newNotification(payload))
            break;
          default:
            break;
        }
      })

      return () => {
        socket.off("allUsers")
        socket.off("notification")
      }
    }
    // eslint-disable-next-line
  }, [socket_connected])




  return (
    <ThemeProvider theme={lightTheme}>
      <BrowserRouter>
      <LayoutWrapper>
        <SnackbarProvider classes={{
          variantInfo: "primary-notification"
      }} maxSnack={3}>
          <Routes />
        </SnackbarProvider>
      </LayoutWrapper>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
