import axios from 'axios';
import { API_URI, API_URI_V2 } from ".././config"

/**
 * httpClient Private takes an endpoint, payload, token and a method and
 * returns a promise.
 * 
 * Uses Axios.
 * 
 */

export async function httpImageClient(endpoint, payload, token, listing_id, uploadprogress, signal = "") {
    const config = {
        method: 'POST',
        url: API_URI + endpoint,
        headers: {
            'token': token,
            'listing_id': listing_id,
        },
        data: payload,
        onUploadProgress: uploadprogress
    }

    const res = await axios(config);

    return res
}


export async function httpListingClient(endpoint, payload, method, token, listing_id, signal = "", api_ver = null) {
    var data = JSON.stringify(payload);

    if(signal === "") {
        const config = {
            method: method,
            url: api_ver === null ? API_URI + endpoint : API_URI_V2 + endpoint,
            headers: {
                'Content-Type': 'application/json',
                'token': token,
                "listing_id": listing_id
            },
            data: data,
        };

        const res = await axios(config)

        return res;

    } else {
        const config = {
            method: method,
            url: API_URI + endpoint,
            headers: {
                'Content-Type': 'application/json',
                'token': token,
                "listing_id": listing_id
            },
            data: data,
            cancelToken: signal
        };

        const res = await axios(config)

        return res;
    }
}

async function httpClient(endpoint, payload, method, token, signal = "") {
    var data = JSON.stringify(payload);

    if(signal === "") {
        const config = {
            method: method,
            url: API_URI + endpoint,
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            data: data,
        };

        const res = await axios(config)

        return res;

    } else {
        const config = {
            method: method,
            url: API_URI + endpoint,
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            data: data,
            cancelToken: signal
        };

        const res = await axios(config)

        return res;
    }
}

export default httpClient
