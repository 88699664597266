import { createStore, combineReducers } from "redux"
import { applyMiddleware } from 'redux'
import authReducer from "./auth/authReducer"
import sockReducer from "./socket/socketReducer"
import notificationReducer from "./notification/notificationReducer"
import dlReducer from "./dashboard-landing/dlReducer"
import searchReducer from "./listings/searchReducer"
import listingsReducer from "./listings/listingsReducer"
import mlReducer from "./manage-listing/mlReducer"
import mdReducer from "./manage-draft/mdReducer"
import mlPhotoReducer from "./manage-listing/mlPhotoReducer"
import mdPhotoReducer from "./manage-draft/mdPhotoReducer"
import mlListingTitleReducer from "./manage-listing/mlListingTitleReducer"
import mlListingDescReducer from "./manage-listing/mlListingDescReducer"
import mlListingSpaceReducer from "./manage-listing/mlListingSpaceReducer"
import mlListingGuestAccessReducer from "./manage-listing/mlListingGuestAccessReducer"
import mlListingGuestInteractionReducer from "./manage-listing/mlListingGuestInteractionReducer"
import mlListingNeighbourhoodReducer from "./manage-listing/mlListingneighbourhoodReducer"
import mlListingGettingAround from "./manage-listing/mlListingGettingAroundReducer"
import mlListingOtherThings from "./manage-listing/mlListingOtherThingsReducer"
import mlListingSEOTitle from "./manage-listing/mlListingSEOTitleReducer"
import mlListingSEODescription from "./manage-listing/mlListingSEODescriptionReducer"
import mlListingSEOKeywords from "./manage-listing/mlListingSEOKeywordsReducer"
import mlListingRoomSpaces from "./manage-listing/mlListingRoomSpacesReducer"
import mlListingAmenities from "./manage-listing/mlListingAmenitiesReducer"
import mlListingFacilities from "./manage-listing/mlListingFacilitiesReducer"
import mlListingPropertyType from "./manage-listing/mlListingPropertyTypeReducer"
import mlListingNumOfGuests from "./manage-listing/mlListingNumOfGuestsReducer"
import mlListingLocation from "./manage-listing/mlListingLocationReducer"
import mlListingHomeowner from "./manage-listing/mlListingHomeownerReducer"
import mlListingAdditionalRules from "./manage-listing/mlListingAdditionalRulesReducer"
import mlListingDisable from "./manage-listing/mlListingDisableReducer"
import { composeWithDevTools } from 'redux-devtools-extension'
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

/**
 * Creates the redux store here. Uses rootReducer variable to
 * combine all reducers.
 * 
 * Uses redux thunk as a middleware to provide action/async 
 * functions to Actions. 
 */

const rootReducer = combineReducers({ auth: authReducer,
                                      sock: sockReducer,
                                      notification: notificationReducer,
                                      dl: dlReducer,
                                      listings: listingsReducer,
                                      search: searchReducer,
                                      ml: mlReducer,
                                      md: mdReducer,
                                      mlphoto: mlPhotoReducer,
                                      mdphoto: mdPhotoReducer,
                                      mllistingtitle : mlListingTitleReducer,
                                      mllistingdesc : mlListingDescReducer,
                                      mllistingspace : mlListingSpaceReducer,
                                      mllistingguestaccess: mlListingGuestAccessReducer,
                                      mllistingguestinteraction:  mlListingGuestInteractionReducer,
                                      mllistingneighbourhood: mlListingNeighbourhoodReducer,
                                      mllistinggettingaround: mlListingGettingAround,
                                      mllistingotherthings: mlListingOtherThings,
                                      mllistingseotitle: mlListingSEOTitle,
                                      mllistingseodescription: mlListingSEODescription,
                                      mllistingseokeywords: mlListingSEOKeywords,
                                      mllistingroomspaces: mlListingRoomSpaces,
                                      mllistingamenities: mlListingAmenities,
                                      mllistingfacilities: mlListingFacilities,
                                      mllistingpropertytype: mlListingPropertyType,
                                      mllistingnumofguests: mlListingNumOfGuests,
                                      mllistinglocation: mlListingLocation,
                                      mllistinghomeowner: mlListingHomeowner,
                                      mllistingadditionalrules: mlListingAdditionalRules,
                                      mllistingdisable: mlListingDisable
                                    })

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(logger, thunkMiddleware)))

export default store