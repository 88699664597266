import { EDIT_LISTING_SEO_DESCRIPTION,
         CLOSE_LISTING_SEO_DESCRIPTION,
         PENDING_SUBMIT_LISTING_SEO_DESCRIPTION,
         COMPLETE_SUBMIT_LISTING_SEO_DESCRIPTION,
         FAILED_SUBMIT_LISTING_SEO_DESCRIPTION
    } from "./mlTypes"


const initialState = {
    pendingsubmit: false,
    failedsubmit: false,
    completesubmit: false,
    propertyseodescriptionediting: false
}


const mlListingSEODescription = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_LISTING_SEO_DESCRIPTION: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertyseodescriptionediting: true
        }

        case CLOSE_LISTING_SEO_DESCRIPTION: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: false,
            propertyseodescriptionediting: false
        }

        case PENDING_SUBMIT_LISTING_SEO_DESCRIPTION: return {
            ...state,
            pendingsubmit: true,
            failedsubmit: false,
            completesubmit: false
        }

        case COMPLETE_SUBMIT_LISTING_SEO_DESCRIPTION: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: false,
            completesubmit: true
        }
        
        case FAILED_SUBMIT_LISTING_SEO_DESCRIPTION: return {
            ...state,
            pendingsubmit: false,
            failedsubmit: true,
            completesubmit: true
        }
    
        default: return state
    }
}

export default mlListingSEODescription