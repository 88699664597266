import React from 'react'
import { Grid } from '@material-ui/core'
import LoggedInMenu from "./loggedinnav"
import LogoutMenu from "./logoutnav"
import { useSelector } from 'react-redux'

function Header() {
    /**
     * Header html element contains a constant logo.
     * And a navigation bar in devices larger than sm breakpoint.
     * Todo: A burger menu button will appear.
     * Todo: Logged in state navigation bar.
     */
    const { logged_in } = useSelector(state => state.auth);


    return (
        <div className="header-container">
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={logged_in ? 6 : 10} sm={6} lg={4}>
                    <Grid container>
                        <Grid item lg={12}>
                            <div className="logo">Roamhome</div>
                            <div className="logo-caption">Dashboard</div>
                        </Grid>
                    </Grid>
                </Grid>

                {
                    logged_in ? (<LoggedInMenu />) : (<LogoutMenu />)
                }
            </Grid>
        </div>
    )
}

export default Header
