export const NEW_BOOKING_REQUEST = "NEW_BOOKING_REQUEST"
export const NEW_CONFIRMED_REQUEST = "NEW_CONFIRMED_REQUEST"
export const ADD_BOOKING_REQUESTS = "ADD_BOOKING_REQUESTS"
export const ADD_CONFIRMED_BOOKINGS = "ADD_CONFIRMED_BOOKINGS"
export const ACTIVE_BOOKING_REQUEST = "ACTIVE_BOOKING_REQUEST"
export const ACTIVE_CONFIRMED_BOOKINGS = "ACTIVE_CONFIRMED_BOOKINGS"
export const FETCHING_BOOKING_REQUEST = "FETCHING_BOOKING_REQUEST"
export const FETCHING_CONFIRMED_BOOKINGS = "FETCHING_CONFIRMED_BOOKINGS"
export const BOOKING_REQUEST_ACCEPT_ACTION_1 = "BOOKING_REQUEST_ACCEPT_ACTION_1"
export const BOOKING_REQUEST_REJECT_ACTION_1 = "BOOKING_REQUEST_REJECT_ACTION_1"
export const BOOKING_REQUEST_CANCEL_ACTION = "BOOKING_REQUEST_CANCEL_ACTION"
export const BOOKING_REQUEST_ACTION_PENDING = "BOOKING_REQUEST_ACTION_PENDING"
export const BOOKING_REQUEST_ACTION_COMPLETE = "BOOKING_REQUEST_ACTION_COMPLETE"
export const BOOKING_REQUEST_ACTION_FAILED = "BOOKING_REQUEST_ACTION_FAILED"
